import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default function MiComunidad() {
    const [comunidad, setComunidad] = useState(null);
    const [listaFiltrada, setListaFiltrada] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchComunidad = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (user) {
                    const db = getFirestore();
                    const docRef = doc(db, "usuarios", user.email);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        setComunidad(docSnap.data().comunidad);
                    } else {
                        console.error("No se encontró el documento.");
                    }
                }
            } catch (error) {
                console.error("Error al obtener la comunidad:", error);
            }
        };

        fetchComunidad();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/confirmandos`);
                const listaGeneral = response.data;

                if (comunidad && Array.isArray(listaGeneral)) {
                    const filtrados = listaGeneral.filter(item => item.COMUNIDAD === comunidad);
                    setListaFiltrada(filtrados);
                } else {
                    console.error("listaGeneral no es un arreglo o está vacío.");
                }
            } catch (error) {
                setError("Error al cargar los datos del backend.");
                console.error("Error al obtener datos del backend:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [comunidad, backendUrl]);

    if (loading) {
        return <p>Cargando...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="container mx-auto mt-8">
            <h1 className="text-2xl font-bold mb-4">Mi Comunidad</h1>
            {comunidad ? (
                listaFiltrada.length > 0 ? (
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="text-left py-3 px-4 font-semibold text-sm">ID</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Nombres</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Apellidos</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Edad</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Número de Celular</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Colegio</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Bautizo</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Género</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Pago</th>
                                <th className="text-left py-3 px-4 font-semibold text-sm">Comunidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listaFiltrada.map(item => (
                                <tr key={item.ID_GENERAL} className="border-b">
                                    <td className="py-3 px-4">{item.ID_CONFIRMANDO}</td>
                                    <td className="py-3 px-4">{item.NOMBRES}</td>
                                    <td className="py-3 px-4">{item.APELLIDOS}</td>
                                    <td className="py-3 px-4">{item.EDAD}</td>
                                    <td className="py-3 px-4">{item.CELULAR}</td>
                                    <td className="py-3 px-4">{item.COLEGIO}</td>
                                    <td className="py-3 px-4">{item.BAUTISMO}</td>
                                    <td className="py-3 px-4">{item.GENERO}</td>
                                    <td className="py-3 px-4">{item.PAGO}</td>
                                    <td className="py-3 px-4">{item.COMUNIDAD}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No hay datos para la comunidad {comunidad}</p>
                )
            ) : (
                <p>Cargando...</p>
            )}
        </div>
    );
}
