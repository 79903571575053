import axios from 'axios';
import React, { useEffect, useState } from 'react';

// Función para eliminar tildes y convertir a minúsculas
const normalizeString = (str) => {
    return str
        .toLowerCase()
        .normalize("NFD") // Descompone los caracteres con tildes
        .replace(/[\u0300-\u036f]/g, ""); // Elimina los diacríticos (tildes)
};

export default function CatequistasEstadoTodos() {
    const [catequistas, setCatequistas] = useState([]);
    const [filteredCatequistas, setFilteredCatequistas] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/catequistas_estado`);
                setCatequistas(response.data);
                setFilteredCatequistas(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [backendUrl]);

    const handleSearch = (e) => {
        const query = normalizeString(e.target.value); // Normalizar la consulta de búsqueda
        setSearchQuery(query);

        // Filtrar catequistas con la función normalizeString
        const filtered = catequistas.filter(catequista => {
            const fullName = normalizeString(`${catequista.NOMBRES} ${catequista.APELLIDOS}`);
            const categoria = normalizeString(catequista.CATEGORIA || '');
            const estado = normalizeString(catequista.ESTADO || '');
            const observacion = normalizeString(catequista.OBSERVACION || '');

            return (
                fullName.includes(query) ||
                categoria.includes(query) ||
                estado.includes(query) ||
                observacion.includes(query)
            );
        });

        const uniqueFiltered = Array.from(new Set(filtered.map(c => c.ID_CATEQUISTA)))
            .map(id => filtered.find(c => c.ID_CATEQUISTA === id));

        setFilteredCatequistas(uniqueFiltered);
    };

    return (
        <div className="w-screen pt-24 flex flex-col items-center">
            {/* Campo de búsqueda */}
            <input
                type="text"
                className="w-4/5 sm:w-1/2 mb-6 p-2 border border-gray-400 rounded-lg"
                placeholder="Buscar catequistas por nombre, categoría, estado, etc."
                value={searchQuery}
                onChange={handleSearch}
            />
            
            {/* Mostrar resultados filtrados */}
            <div className="w-full flex flex-wrap justify-center">
                {filteredCatequistas.map(catequista => (
                    <div 
                        key={catequista.ID_CATEQUISTA} 
                        className="flex h-28 w-full sm:w-72 border-2 border-black rounded-lg p-2 m-2"
                    >
                        <div className="flex w-full h-full justify-between">
                            <div className=' h-full flex flex-col '>
                                <div className=' font-semibold'>{catequista.NOMBRES}</div>
                                <div className=' text-sm'>{catequista.APELLIDOS}</div>
                                <div className='mt-3  justify-self-end text-red-800  font-semibold'>{catequista.CATEGORIA}</div>
                            </div>
                            <div className="flex flex-col justify-between h-full">
                                <div className=' text-xs font-bold text-blue-600 '>{catequista.SABADO}</div>
                                <div className=' text-xs font-normal '>{catequista.OBSERVACION}</div>
                            </div>
                        </div>
                    </div>
                ))}

                {/* Si no hay resultados */}
                {filteredCatequistas.length === 0 && (
                    <div className="w-full text-center text-red-500 mt-4">
                        No se encontraron resultados.
                    </div>
                )}
            </div>
        </div>
    );
}
