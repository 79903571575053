// AgregarCategorias.js
import React, { useState } from 'react';
import { actualizarCategoria } from '../../firebase'; // Asegúrate de importar correctamente la función

const initialData = [
  { correo: 'cindy_74_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
  { correo: 'cristian_72_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'si', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
  { correo: 'cindy_74_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'cristian_72_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'si', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'david_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'daysi_70_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'eber_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'si', secretaria: 'no' },
{ correo: 'erik_70_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'jhonathan_70_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'jorge_70_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'juan_41_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'karen_70_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'leslie_72_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'marco_74_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'mayda_74_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'paúl_70_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'si', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'sheyla_75_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'si', secretaria: 'no' },
{ correo: 'terry_70_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'alan_60_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'ana_76_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'anali_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'andrea_78_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'angie_60_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'carlos_76_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'clara_75_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'cristhian_60_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'dany_60_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'gimena_61_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'gustavo_73_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'jhoselin_74_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'jose_60_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'joseph_61_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'leydi_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'magdalena_73_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'rocio_74_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'sebastian_61_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'sulenca_72_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'yhoseph_61_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'zeider_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'ana_78_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'angela_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'cristian_77_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'eylem_62_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'si', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'fabrizio_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'harry_72_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'karol_75_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'maria_74_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'si', secretaria: 'no' },
{ correo: 'mayled_60_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'midian_76_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'si', secretaria: 'no' },
{ correo: 'pablo_75_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'si', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'sahun_71_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'zulema_75_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'no' },
{ correo: 'brandon_54_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'si' },
{ correo: 'luz_24_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'si' },
{ correo: 'nelson_78_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'si' },
{ correo: 'secretaria_1_ecsa@gmail.com', admin: 'no', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'si' },
{ correo: 'crisvi072@gmail.com', admin: 'si', catequista: 'si', comunidad: 'no', confirmando: 'no', formador_catequistas: 'no', formador_iniciados: 'no', formador_pre_catequistas: 'no', secretaria: 'si' },

  // Agrega más datos aquí según tu tabla
];

function AgregarCategorias() {
  const [data, setData] = useState(initialData);

  // Función para cambiar los valores de los inputs
  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  // Generar un número aleatorio de 4 cifras
  const generateRandomNumber = () => Math.floor(1000 + Math.random() * 9000);

  // Función para guardar los datos en Firebase
  const handleSave = async () => {
    for (const item of data) {
      const { correo, ...fields } = item;

      // Recorre todos los campos de un usuario y si es "si", actualiza en la colección "categoria"
      for (const [key, value] of Object.entries(fields)) {
        if (value === 'si') {
          const number = generateRandomNumber();
          const fieldValue = { [number]: correo }; // Registra el valor en el formato {número: correo}

          // Llamar a la función de Firebase para actualizar en la colección "categoria"
          const success = await actualizarCategoria(key, fieldValue);
          if (!success) {
            console.error(`Error al actualizar la categoría ${key} para el usuario ${correo}`);
          }
        }
      }
    }
  };

  return (
    <div className='pt-24 px-4'>
      <h1 className='text-2xl font-bold mb-6'>Agregar Categorías</h1>
      <div className='overflow-x-auto'>
        <table className='min-w-full bg-white border border-gray-200 rounded-lg shadow-md'>
          <thead>
            <tr className='w-full bg-gray-100 border-b'>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Correo</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Admin</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Catequista</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Comunidad</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Confirmando</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Formador Catequistas</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Formador Iniciados</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Formador Pre Catequistas</th>
              <th className='py-3 px-4 text-left text-gray-600 font-semibold'>Secretaria</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.correo} className={`border-b ${index % 100 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                <td className='py-2 px-4'>{item.correo}</td>
                {Object.keys(item).slice(1).map((field) => (
                  <td key={field} className='py-2 px-4'>
                    <input
                      type="checkbox"
                      checked={item[field] === 'si'}
                      onChange={(e) => handleInputChange(index, field, e.target.checked ? 'si' : 'no')}
                      className='form-checkbox h-5 w-5 text-blue-500'
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={handleSave}
        className='mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200'
      >
        Guardar
      </button>
    </div>
  );
}

export default AgregarCategorias;
