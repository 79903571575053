import React, { useState, useEffect } from 'react';
import logo from '../assets/images/ecsa_logo.png';
import { IoMenu, IoReload, IoClose } from "react-icons/io5";
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useData } from './DataContext';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom'; // Importa el hook useNavigate
import logoTiktok from '../assets/images/tiktokLOGO.png';
import logoFace from '../assets/images/facebookLOGO.png';
import logoYoutube from '../assets/images/youtube.png'
import BotonActualizarData from './BotonActualizarData';

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const { usuario } = useData();
    const [availableRoles, setAvailableRoles] = useState([]);
    const navigate = useNavigate(); // Usa el hook useNavigate

    useEffect(() => {
        const cargarRolesDisponibles = async () => {
            if (auth.currentUser) {
                const userEmail = auth.currentUser.email;
                const roles = [];
                const categoriasSnapshot = await getDocs(collection(db, 'categoria'));

                categoriasSnapshot.forEach((doc) => {
                    const data = doc.data();
                    for (const key in data) {
                        if (data[key] === userEmail) {
                            roles.push(doc.id); // Agrega el nombre del documento (categoría) al array de roles disponibles
                        }
                    }
                });

                setAvailableRoles(roles);
            }
        };

        cargarRolesDisponibles();
    }, []);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleSignOut = () => {
        signOut(auth).then(() => {
            // Redirige a la página de inicio de sesión después de cerrar sesión
            navigate('/'); // Ajusta la ruta según corresponda
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <>
            <div className={`fixed flex flex-col top-0 left-0 h-full w-72 bg-slate-100 text-black transition-transform duration-300 z-50 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                <div className='flex flex-col h-full pb-4'>
                    <div className='relative w-full bg-red-900 p-4  items-center flex flex-col pt-4'>
                        <button onClick={toggleSidebar} className="absolute top-4 right-4 text-white rounded-full hover:bg-blue-700 font-bold">
                            <IoClose size={30} />
                        </button>
                        <div className='flex items-center mb-2 w-full justify-between'>
                            <div className='flex flex-row space-x-2 items-center'>
                                <div className='h-16'>
                                    <img src={logo} alt='Logo ECSA' className='h-full' />
                                </div>
                                <div className="font-semibold justify-self-end text-white text-2xl texto_estirado hover:cursor-pointer">
                                    ECSA
                                </div>
                            </div>
                        </div>
                        <div className='    flex space-x-2'>
                            <a href="https://www.facebook.com/profile.php?id=61558000381151" target="_blank" rel="noopener noreferrer">
                                <div className=' rounded-full h-10 mx-1 hover:cursor-pointer'> <img src={logoFace} alt='Logo ECSA' className='  h-full' /></div>
                            </a>
                            <a href="https://www.tiktok.com/@equipodecatequesi" target="_blank" rel="noopener noreferrer">
                                <div className=' rounded-full h-10 mx-1 hover:cursor-pointer'> <img src={logoTiktok} alt='Logo ECSA' className='  h-full' /></div>

                            </a>
                            <a href="https://www.youtube.com/@RinconDelCatequistaOficial" target="_blank" rel="noopener noreferrer">
                                <div className=' rounded-full h-10 mx-1 hover:cursor-pointer'> <img src={logoYoutube} alt='Logo ECSA' className='  h-full' /></div>
                            </a>

                        </div>

                        <div className='pt-2 texto_estirado text-white font-semibold'>
                            {/* Bienvenido {usuario.nombre} */}
                        </div>
                        <div className=' texto_estirado text-white'>
                            {/* {usuario.correo} */}
                        </div>
                    </div>
                    <div className='flex flex-col w-full p-3 space-y-4 flex-grow overflow-y-auto h-full text-lg  '>


                        {availableRoles.includes('admin') && (
                            <>
                                <a href="/admin" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black text-white text-center bg-red-700">Admin</a>
                                <a href="/agregar_usuarios" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Agregar Firebase</a>
                                <a href="/registrar_usuarios" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Registrar Firebase</a>
                                <a href="/editar_usuarios" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Editar Usuarios </a>
                                <a href="/agregar_categorias" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Agregar Categorias </a>


                            </>
                        )}

                        {availableRoles.includes('secretaria') && (
                            <>
                                <a href="/secretaria" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black text-white text-center bg-red-700">Secretaria</a>
                                <a href="/asistencia_qr" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia Qr</a>
                                <a href="/asistencia_general" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia General</a>


                            </>
                        )}
                        {availableRoles.includes('catequista') && (
                            <>
                                <a href="/catequista" className=" font-semibold px-3   rounded-3xl text-white text-center bg-red-700 hover:bg-white hover:text-black ">Catequista</a>
                                <a href="/estado_catequistas" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Estado de Catequistas</a>
                                <a href="/catequista_asistencia" className=" font-semibold px-3 mx-3 rounded-3xl hover:bg-white hover:text-black">Mi Asistencia</a>
                                <a href="/catequista_perfil" className=" font-semibold px-3 mx-3 rounded-3xl hover:bg-white hover:text-black">Catequista Perfil</a>

                            </>

                        )}


                        {availableRoles.includes('confirmando') && (
                            <>
                                <a href="/confirmando" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black text-white text-center bg-red-700"> Confirmando</a>
                                <a href="/asistencia_confirmando" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia Confirmando</a>
                                <a href="/comunidad_confirmando" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Comunidad Confirmando</a>

                            </>
                        )}


                        {availableRoles.includes('comunidad') && (
                            <>
                                <a href="/comunidad" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black text-white text-center bg-red-700"> Comundidad</a>
                                <a href="/lista_general" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Lista General</a>
                                <a href="/mi_comunidad" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Mi Comunidad</a>
                                <a href="/asistencia_mi_comunidad" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia Mi Comunidad</a>


                            </>
                        )}
                        {availableRoles.includes('formador_catequistas') && (
                            <>
                                <a href="/formador" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black text-white text-center bg-red-700"> Formador Catequistas</a>
                                <a href="/lista_catequistas" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black"> Lista de catequistas</a>
                                <a href="/catequista_estado" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black"> Estado de catequistas</a>
                                <a href="/asistencia_qr_catequistas" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia Qr</a>
                                <a href="/asistencia_salida_qr_catequistas1" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia de Salida</a>



                            </>
                        )}
                        {availableRoles.includes('formador_pre_catequistas') && (
                            <>
                                <a href="/formador" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black text-white text-center bg-red-700"> Formador Pre-Catequistas</a>
                                <a href="/lista_precatequistas" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black"> Lista de Pre Catequistas</a>
                                <a href="/precatequistas_estado" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black"> Estado de Pre Catequistas</a>
                                <a href="/asistencia_qr_precatequistas" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia Qr</a>
                                <a href="/asistencia_salida_qr_catequistas2" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia de Salida</a>


                            </>
                        )}
                        {availableRoles.includes('formador_iniciados') && (
                            <>
                                <a href="/formador" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black text-white text-center bg-red-700"> Formador Iniciados</a>
                                <a href="/lista_iniciados" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black"> Lista de Iniciados</a>
                                <a href="/iniciados_estado" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black"> Estado de Iniciados</a>
                                <a href="/asistencia_qr_iniciados" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia Qr</a>
                                <a href="/asistencia_salida_qr_catequistas3" className=" font-semibold px-3  mx-3 rounded-3xl hover:bg-white hover:text-black">Asistencia de Salida</a>


                            </>
                        )}
                    </div>
                    <button onClick={handleSignOut} className='justify-self-end bg-black p-3 rounded-md text-white m-2 '>Salir / Cerrar Sesión</button>
                </div>
            </div>

            <div className='fixed top-0 left-0 z-40 w-full bg-red-800 p-2 flex space-x-1 items-center justify-between'>
                <div className='flex'>
                    <button onClick={toggleSidebar} className="text-white hover:bg-blue-700 font-bold  px-1 rounded">
                        <IoMenu size={30} />
                    </button>
                    <div className='flex flex-row space-x-1 items-center'>
                        <div className='h-10'>
                            <img src={logo} alt='Logo ECSA' className='h-full' />
                        </div>
                        <div className="font-semibold text-white text-3xl texto_estirado hover:cursor-pointer">
                            ECSA
                        </div>
                    </div>
                </div>
                <BotonActualizarData></BotonActualizarData>
            </div>
        </>
    );
}

export default Sidebar;
