import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export default function CatequistaPerfil() {
  const [usuarioData, setUsuarioData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const db = getFirestore(getApp());

      const userEmail = auth.currentUser?.email;

      if (userEmail) {
        try {
          const docRef = doc(db, 'usuarios', userEmail);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUsuarioData(docSnap.data());
          } else {
            console.log("No se encontró el documento.");
          }
        } catch (error) {
          console.error("Error al obtener el documento:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  // Construir la URL de la imagen usando usuarioData.codigo
  const qrCodeUrl = usuarioData ? `https://quickchart.io/qr?size=500x500&dark=000000&centerImageUrl=https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjXVTGNB9L4-gx24WOudRwzOByQXQ2Ak4wR8pmzhDMfRVL3GgqxCKV-7qg3PBJ04dm4ApSVXLb7mFGUm-STDtbhCNfqEyFUKUKEkPuu0tl7DtsknC798W9QpP0v_hfutq-VMvkut_345mTraBgKTgYudTmjDLOq1zGZvRbSUDDa5eIINQNuUwJ4_R1wxOSG/w498-h542/eq%20(1)%20(1).jpg&text=${usuarioData.codigo}` : '';

  return (
    <div className="pt-24 flex flex-col items-center ">
      <h1 className="text-2xl font-bold mb-8">Catequista Perfil</h1>
      {usuarioData ? (
        <div className="w-full max-w-sm flex justify-between max-md:flex-col px-2">
          <div className="flex flex-col items-center mb-4">
            {/* Mostrar la imagen del código QR como un cuadrado */}
            <img 
              src={qrCodeUrl} 
              alt="Código QR" 
              className="w-32 h-32 object-cover rounded mb-4 cursor-pointer"
              onClick={() => setIsModalOpen(true)} // Abrir el modal al hacer clic
            />
            <p className="text-lg font-semibold">{usuarioData.categoria}</p>
            <p className="text-lg font-semibold">CODIGO</p>
            <p className="text-lg font-semibold">{usuarioData.codigo}</p>


          </div>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium">Nombre:</label>
              <p className="border rounded-md p-2">{usuarioData.nombre}</p>
            </div>
            <div>
              <label className="block text-sm font-medium">Apellido:</label>
              <p className="border rounded-md p-2">{usuarioData.apellido}</p>
            </div>
            <div>
              <label className="block text-sm font-medium">DNI:</label>
              <p className="border rounded-md p-2">{usuarioData.DNI}</p>
            </div>
            <div>
              <label className="block text-sm font-medium">Correo:</label>
              <p className="border rounded-md p-2">{usuarioData.correo}</p>
            </div>
            <div>
              <label className="block text-sm font-medium">Contraseña:</label>
              <p className="border rounded-md p-2">{usuarioData.contraseña}</p>
            </div>
          </div>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}

      {/* Modal para mostrar el código QR en grande */}
      {isModalOpen && (
        <div 
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={() => setIsModalOpen(false)} // Cerrar el modal al hacer clic en cualquier parte
        >
          <img 
            src={qrCodeUrl} 
            alt="Código QR Grande" 
            className="max-w-full max-h-full"
          />
        </div>
      )}
    </div>
  );
}
