import React, { useEffect, useState } from 'react';
import { useData } from '../../DataContext';

export default function AsistenciaConfirmando() {
    const { asistenciaGeneral, usuario, loading } = useData();
    const [filtradasAsistencia, setFiltradasAsistencia] = useState([]);

    useEffect(() => {
        if (usuario && asistenciaGeneral.length > 0) {
            const codigoUsuario = usuario.codigo; // Usa el código del usuario desde el contexto

            const asistenciaFiltrada = asistenciaGeneral.filter(
                (asistencia) => asistencia.ID_GENERAL === codigoUsuario
            );
            setFiltradasAsistencia(asistenciaFiltrada);
        }
    }, [usuario, asistenciaGeneral]);

    if (loading) {
        return <div className="text-center p-4">Loading...</div>;
    }

    return (
        <div className='pt-24 px-6'>
            <h1 className='text-2xl font-bold mb-6 text-center text-gray-800'>Asistencia Confirmando</h1>
            {filtradasAsistencia.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">ID General</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Nombres</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Apellidos</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Comunidad</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Hora</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtradasAsistencia.map((asistencia) => (
                                <tr key={asistencia.ID_ASISTENCIA} className="border-b hover:bg-gray-50">
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.ID_GENERAL}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.NOMBRES}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.APELLIDOS}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.COMUNIDAD}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.HORA}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.FECHA}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="text-center text-gray-600">No hay registros de asistencia disponibles.</div>
            )}
        </div>
    );
}
