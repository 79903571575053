import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function IniciadosEstado() {
    const [catequistas, setCatequistas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingCatequista, setEditingCatequista] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formValues, setFormValues] = useState({});
    
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/catequistas_estado`);
                // Filtrar catequistas donde la categoría sea "Catequista"
                const catequistasFiltrados = response.data.filter(catequista => catequista.CATEGORIA === 'Iniciado');
                setCatequistas(catequistasFiltrados);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [backendUrl]);

    const handleEditClick = (catequista) => {
        setEditingCatequista(catequista);
        setFormValues(catequista);
        setShowModal(true);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSaveClick = async () => {
        try {
            const response = await axios.put(`${backendUrl}/catequistas_estado/${formValues.ID_CATEQUISTA}`, formValues);
            console.log('Update successful:', response.data);
            setShowModal(false);
            alert('¡Los cambios fueron guardados exitosamente!');
            setCatequistas((prevCatequistas) =>
                prevCatequistas.map((catequista) =>
                    catequista.ID_CATEQUISTA === formValues.ID_CATEQUISTA ? formValues : catequista
                )
            );
        } catch (error) {
            console.error('Error updating:', error);
            alert('Hubo un error al guardar los cambios.');
        }
    };

    const handleCancelClick = () => {
        setShowModal(false);
        setEditingCatequista(null);
    };

    if (loading) {
        return <div className="text-center mt-5">Cargando...</div>;
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Actividad de Catequistas</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr className="w-full bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">ID Catequista</th>
                            <th className="py-3 px-6 text-left">Nombres</th>
                            <th className="py-3 px-6 text-left">Apellidos</th>
                            <th className="py-3 px-6 text-left">Sabado</th>
                            <th className="py-3 px-6 text-left">Observación</th>
                            <th className="py-3 px-6 text-left">Estado</th>
                            <th className="py-3 px-6 text-left">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm ">
                        {catequistas.map(catequista => (
                            <tr key={catequista.ID_CATEQUISTA} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left">{catequista.ID_CATEQUISTA}</td>
                                <td className="py-3 px-6 text-left">{catequista.NOMBRES}</td>
                                <td className="py-3 px-6 text-left">{catequista.APELLIDOS}</td>
                                <td className="py-3 px-6 text-left">{catequista.SABADO}</td>
                                <td className="py-3 px-6 text-left">{catequista.OBSERVACION}</td>
                                <td className="py-3 px-6 text-left">{catequista.ESTADO}</td>

                                <td className="py-3 px-6 text-left">
                                    <button
                                        className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                                        onClick={() => handleEditClick(catequista)}
                                    >
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h2 className="text-xl font-bold mb-4">Editar Catequista</h2>
                        <form>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombres">
                                    Nombres
                                </label>
                                <input
                                    id="nombres"
                                    name="NOMBRES"
                                    type="text"
                                    value={formValues.NOMBRES || ''}
                                    onChange={handleFormChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="apellidos">
                                    Apellidos
                                </label>
                                <input
                                    id="apellidos"
                                    name="APELLIDOS"
                                    type="text"
                                    value={formValues.APELLIDOS || ''}
                                    onChange={handleFormChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="estado">
                                    Estado
                                </label>
                                <select
                                    id="estado"
                                    name="ESTADO"
                                    value={formValues.ESTADO || ''}
                                    onChange={handleFormChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option value="" disabled>Seleccionar estado</option>
                                    <option value="LLegara Temprano">Llegará Temprano</option>
                                    <option value="LLegara Tarde">Llegará Tarde</option>
                                    <option value="Permiso No Asistira">Permiso No Asistirá</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="observacion">
                                    Observación
                                </label>
                                <textarea
                                    id="observacion"
                                    name="OBSERVACION"
                                    value={formValues.OBSERVACION || ''}
                                    onChange={handleFormChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={handleSaveClick}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
                                >
                                    Guardar
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCancelClick}
                                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
