import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-scanner';

const QrScaner = ({ onScanResult }) => {
    const [scanning, setScanning] = useState(true); // Inicia directamente con escaneo activo
    const [useBackCamera, setUseBackCamera] = useState(true);
    const [torchOn, setTorchOn] = useState(false);

    useEffect(() => {
        // Inicia el escaneo al montar el componente
        startScanning();
    }, []);

    const handleScan = (data) => {
        if (data) {
            onScanResult(data.text);  // Pasa el resultado al componente padre
            setScanning(false);  // Oculta el escáner QR
        }
    };

    const handleError = (err) => {
        console.error('Error handling QR Scan:', err);
    };

    const toggleCamera = () => {
        setUseBackCamera(!useBackCamera);
        // Reiniciar escaneo después de cambiar cámara
        setScanning(false);
        setTimeout(() => setScanning(true), 100);
    };

    const toggleTorch = () => {
        setTorchOn(!torchOn);
        // Reiniciar escaneo después de cambiar linterna
        setScanning(false);
        setTimeout(() => setScanning(true), 100);
    };

    const startScanning = () => {
        setScanning(true);
    };

    const stopScanning = () => {
        setScanning(false);
        onScanResult(''); // Enviar vacío o 0 al componente padre
    };

    return (
        <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
            {scanning && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    overflow: 'hidden',
                    zIndex: 9999,
                }}>
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        constraints={{
                            video: {
                                facingMode: useBackCamera ? 'environment' : 'user',
                                torch: torchOn
                            }
                        }}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <div style={{
                        border: '2px solid white',
                        width: '400px',
                        height: '400px',
                        position: 'absolute',
                        boxSizing: 'border-box',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        pointerEvents: 'none', // Permite hacer clic a través del cuadro
                    }} />
                    <div style={{
                        position: 'absolute',
                        top: 20,
                        right: 20,
                        zIndex: 10000,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <button
                            onClick={toggleCamera}
                            style={{
                                padding: '10px 20px',
                                backgroundColor: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            Cambiar Cámara
                        </button>
                        <button
                            onClick={toggleTorch}
                            style={{
                                padding: '10px 20px',
                                backgroundColor: torchOn ? 'yellow' : 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            {torchOn ? 'Apagar Linterna' : 'Encender Linterna'}
                        </button>
                        <button
                            onClick={stopScanning}
                            style={{
                                padding: '10px 20px',
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            Detener Escaneo
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QrScaner;
