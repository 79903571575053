import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default function AsistenciaMiComunidad() {
    const [comunidad, setComunidad] = useState(null);
    const [listaFiltrada, setListaFiltrada] = useState([]);
    const [fechasUnicas, setFechasUnicas] = useState([]);
    const [listaGeneral, setListaGeneral] = useState([]);
    const [asistenciaGeneral, setAsistenciaGeneral] = useState([]);
    const [loading, setLoading] = useState(true); // Agregamos un estado de carga

    useEffect(() => {
        const fetchComunidad = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (user) {
                    const db = getFirestore();
                    const docRef = doc(db, "usuarios", user.email);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        setComunidad(docSnap.data().comunidad);
                    } else {
                        console.error("No se encontró el documento.");
                    }
                }
            } catch (error) {
                console.error("Error al obtener la comunidad:", error);
            }
        };

        const fetchData = async () => {
            try {
                setLoading(true);
                await fetchComunidad();

                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const [listaResponse, asistenciaResponse] = await Promise.all([
                    axios.get(`${backendUrl}/confirmandos`),
                    axios.get(`${backendUrl}/confirmandos_asistencia`)
                ]);

                setListaGeneral(listaResponse.data);
                setAsistenciaGeneral(asistenciaResponse.data);
            } catch (error) {
                console.error("Error al obtener los datos del backend:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (comunidad && listaGeneral.length > 0) {
            const filtrados = listaGeneral.filter(item => item.COMUNIDAD === comunidad);
            setListaFiltrada(filtrados);
        }
    }, [comunidad, listaGeneral]);

    useEffect(() => {
        if (asistenciaGeneral.length > 0) {
            const fechas = [...new Set(asistenciaGeneral.map(item => item.FECHA))];
            setFechasUnicas(fechas);
        }
    }, [asistenciaGeneral]);

    if (loading) {
        return <p className=' pt-32'>Cargando...</p>;
    }

    if (!comunidad) {
        return <p>Por favor, inicie sesión para ver los datos.</p>;
    }

    if (listaFiltrada.length === 0 && asistenciaGeneral.length === 0) {
        return <p>No hay datos disponibles para la comunidad {comunidad}.</p>;
    }

    return (
        <div className="container mx-auto pt-24">
            <h1 className="text-2xl font-bold mb-4">Mi Comunidad</h1>
            {listaFiltrada.length > 0 && asistenciaGeneral.length > 0 ? (
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="text-left py-3 px-4 font-semibold text-sm">ID</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Nombres</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Apellidos</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Edad</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Número de Celular</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Colegio</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Bautizo</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Género</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Pago</th>
                            <th className="text-left py-3 px-4 font-semibold text-sm">Comunidad</th>
                            {fechasUnicas.map((fecha, index) => (
                                <th key={index} className="text-left py-3 px-4 font-semibold text-sm">{fecha}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {listaFiltrada.map((item, rowIndex) => (
                            <tr key={item.ID_GENERAL} className="border-b">
                                <td className="py-3 px-4">{item.ID_CONFIRMANDO}</td>
                                <td className="py-3 px-4">{item.NOMBRES}</td>
                                <td className="py-3 px-4">{item.APELLIDOS}</td>
                                <td className="py-3 px-4">{item.EDAD}</td>
                                <td className="py-3 px-4">{item.CELULAR}</td>
                                <td className="py-3 px-4">{item.COLEGIO}</td>
                                <td className="py-3 px-4">{item.BAUTISMO}</td>
                                <td className="py-3 px-4">{item.GENERO}</td>
                                <td className="py-3 px-4">{item.PAGO}</td>
                                <td className="py-3 px-4">{item.COMUNIDAD}</td>
                                {fechasUnicas.map((fecha, colIndex) => {
                                    const asistencia = asistenciaGeneral.find(a =>
                                        a.ID_CONFIRMANDO != null && a.FECHA != null &&
                                        a.ID_CONFIRMANDO.toString() === item.ID_CONFIRMANDO?.toString() &&
                                        a.FECHA.toString() === fecha.toString()
                                    );

                                    return (
                                        <td key={colIndex} className="py-3 px-4">
                                            {asistencia ? "Sí" : "No"}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No hay datos disponibles para la comunidad {comunidad}.</p>
            )}
        </div>
    );
}
