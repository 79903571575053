import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Función para eliminar tildes y convertir a minúsculas
const normalizeString = (str) => {
    return str
        .toLowerCase()
        .normalize("NFD") // Descompone los caracteres con tildes
        .replace(/[\u0300-\u036f]/g, ""); // Elimina los diacríticos (tildes)
};

export default function HomePage() {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [listaGeneral, setListaGeneral] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/confirmandos`);
                setListaGeneral(response.data);
                setLoading(false);
            } catch (err) {
                setError('Error al cargar los datos');
                setLoading(false);
            }
        };

        fetchData();
    }, [backendUrl]);

    useEffect(() => {
        if (Array.isArray(listaGeneral)) {
            if (searchQuery === '') {
                // Si la búsqueda está vacía, muestra todos los elementos
                setFilteredList(listaGeneral);
            } else {
                // Filtra los datos cuando cambia la consulta de búsqueda
                handleSearch(searchQuery);
            }
        }
    }, [searchQuery, listaGeneral]);

    const handleSearch = (query) => {
        const normalizedQuery = normalizeString(query); // Normalizar la consulta de búsqueda

        const filtered = listaGeneral.filter(row => {
            // Asegúrate de que cada campo tenga un valor predeterminado si es undefined
            const fields = [
                row.ID_GENERAL || '',
                row.NOMBRES || '',
                row.APELLIDOS || '',
                row.EDAD || '',
                row.CELULAR || '',
                row.COLEGIO || '',
                row.BAUTISMO || '',
                row.GENERO || '',
                row.PAGO || '',
                row.COMUNIDAD || ''
            ].map(field => normalizeString(field.toString()));

            return fields.some(field => field.includes(normalizedQuery));
        });

        setFilteredList(filtered);
    };

    if (loading) {
        return <div>Cargando datos...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className='flex w-full px-10'>
            <div className='mt-20'>
                <h1 className='text-2xl font-bold'>Lista General</h1>
                
                {/* Campo de búsqueda */}
                <input
                    type="text"
                    className="w-full mb-6 p-2 border border-gray-400 rounded-lg"
                    placeholder="Buscar en la lista general..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                <table className='table-auto border-collapse border border-gray-200'>
                    <thead className='text-xs'>
                        <tr>
                            <th className='border border-gray-300 px-4 py-2'>ID_CONFIRMANDO</th>
                            <th className='border border-gray-300 px-4 py-2'>NOMBRES</th>
                            <th className='border border-gray-300 px-4 py-2'>APELLIDOS</th>
                            <th className='border border-gray-300 px-4 py-2'>EDAD</th>
                            <th className='border border-gray-300 px-4 py-2'>NUMERO DE CELULAR</th>
                            <th className='border border-gray-300 px-4 py-2'>COLEGIO</th>
                            <th className='border border-gray-300 px-4 py-2'>SACRAMENTO DE BAUTISMO</th>
                            <th className='border border-gray-300 px-4 py-2'>GENERO</th>
                            <th className='border border-gray-300 px-4 py-2'>PAGO</th>
                            <th className='border border-gray-300 px-4 py-2'>COMUNIDAD</th>
                        </tr>
                    </thead>
                    <tbody className='text-xs'>
                        {Array.isArray(filteredList) && filteredList.length > 0 ? (
                            filteredList.map((row) => (
                                <tr key={row.ID_GENERAL}>
                                    <td className='border border-gray-300 px-4 py-2'>{row.ID_CONFIRMANDO || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.NOMBRES || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.APELLIDOS || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.EDAD || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.CELULAR || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.COLEGIO || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.BAUTISMO || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.GENERO || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.PAGO || ''}</td>
                                    <td className='border border-gray-300 px-4 py-2'>{row.COMUNIDAD || ''}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="10" className='border border-gray-300 px-4 py-2 text-center text-red-500'>
                                    No se encontraron resultados.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
