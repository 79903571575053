import React from 'react';
import Lottie from 'react-lottie';
import AnimacionWelcome from '../../../assets/Animations/welcome.json';

export default function HomeSecretaria() {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: AnimacionWelcome,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className=' pt-16 h-screen flex flex-col items-center justify-center'>
      <div className='text-4xl text-black font-bold text-center mb-8'>
        Bienvenido a Secretaria
      </div>
      <div className='h-96'>
        <Lottie options={defaultOptions}  />
      </div>
    </div>
  );
}
