import React, { useState, useEffect } from 'react';
import { loadUsers, actualizarUsuario, eliminarUsuario } from '../../firebase'; // Asegúrate de que la ruta sea correcta

export default function EditarUsuariosFirebase() {
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await loadUsers();
      setUsuarios(users);
    };

    fetchUsers();
  }, []);

  const handleUpdateUser = async (usuario) => {
    const success = await actualizarUsuario(usuario);
    if (success) {
      setUsuarios(usuarios.map(u => u.id === usuario.id ? usuario : u));
      setIsEditing(false);
      alert('Usuario actualizado correctamente');
    }
  };

  const handleDeleteUser = async (id) => {
    const success = await eliminarUsuario(id);
    if (success) {
      setUsuarios(usuarios.filter(usuario => usuario.id !== id));
      setConfirmOpen(false);
      alert('Usuario eliminado correctamente');
    }
  };

  return (
    <div className='w-full p-24'>
      <div className='w-full flex flex-wrap justify-center'>
        {usuarios.map(usuario => (
          <div 
            key={usuario.id}
            className="flex h-32 w-full sm:w-72 border-2 border-gray-300 rounded-lg p-4 m-2 shadow-lg"
          >
            <div className="flex flex-col w-full">
              <div className='font-semibold text-lg'>{usuario.nombre}</div>
              <div className='text-sm'>{usuario.apellido}</div>
              <div className='mt-2 text-red-800 font-semibold'>{usuario.categoria}</div>
              <div className='mt-2 text-xs'>{usuario.comunidad}</div>
            </div>
            <div className="flex flex-col justify-between ml-4">
              <button 
                className='bg-blue-500 p-2 text-white rounded-lg mb-2'
                onClick={() => { setSelectedUser(usuario); setIsEditing(true); }}
              >
                Editar
              </button>
              <button 
                className='bg-red-500 p-2 text-white rounded-lg'
                onClick={() => { setSelectedUser(usuario); setConfirmOpen(true); }}
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}

        {/* Si no hay resultados */}
        {usuarios.length === 0 && (
          <div className="w-full text-center text-red-500 mt-4">
            No se encontraron usuarios.
          </div>
        )}
      </div>

      {/* Modal para editar usuario */}
      {isEditing && selectedUser && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
          <div className='bg-white p-6 rounded-lg w-11/12 max-w-lg'>
            <h3 className='text-lg font-semibold mb-4'>Editar Usuario</h3>
            <form 
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateUser(selectedUser);
              }}
            >
              <div className='mb-4'>
                <label className='block text-sm font-medium text-gray-700'>Nombre</label>
                <input 
                  type='text' 
                  value={selectedUser.nombre} 
                  onChange={(e) => setSelectedUser({ ...selectedUser, nombre: e.target.value })}
                  className='border border-gray-300 rounded-lg w-full p-2'
                />
              </div>
              <div className='mb-4'>
                <label className='block text-sm font-medium text-gray-700'>Apellido</label>
                <input 
                  type='text' 
                  value={selectedUser.apellido} 
                  onChange={(e) => setSelectedUser({ ...selectedUser, apellido: e.target.value })}
                  className='border border-gray-300 rounded-lg w-full p-2'
                />
              </div>
              <div className='mb-4'>
                <label className='block text-sm font-medium text-gray-700'>Categoría</label>
                <input 
                  type='text' 
                  value={selectedUser.categoria} 
                  onChange={(e) => setSelectedUser({ ...selectedUser, categoria: e.target.value })}
                  className='border border-gray-300 rounded-lg w-full p-2'
                />
              </div>
              <div className='mb-4'>
                <label className='block text-sm font-medium text-gray-700'>Comunidad</label>
                <input 
                  type='text' 
                  value={selectedUser.comunidad} 
                  onChange={(e) => setSelectedUser({ ...selectedUser, comunidad: e.target.value })}
                  className='border border-gray-300 rounded-lg w-full p-2'
                />
              </div>
              <div className='flex justify-end'>
                <button 
                  type='submit' 
                  className='bg-green-500 p-2 text-white rounded-lg mr-2'
                >
                  Guardar
                </button>
                <button 
                  className='bg-gray-500 p-2 text-white rounded-lg'
                  onClick={() => setIsEditing(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Confirmación de eliminación */}
      {confirmOpen && selectedUser && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
          <div className='bg-white p-6 rounded-lg'>
            <p className='mb-4'>¿Estás seguro de que deseas eliminar este usuario?</p>
            <button 
              className='bg-red-500 p-2 text-white rounded-lg mr-2'
              onClick={() => { handleDeleteUser(selectedUser.id); }}
            >
              Sí
            </button>
            <button 
              className='bg-gray-500 p-2 text-white rounded-lg'
              onClick={() => setConfirmOpen(false)}
            >
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
