import React from 'react'

export default function FormularioDinamico({  data, onChange, onSave, onCancel, readonlyFields = []  }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onChange(name, value);
      };
    
      return (
        <div className="bg-white p-5 rounded-lg shadow-lg w-96">
          <h3 className="text-xl font-bold mb-4">Editar Catequista</h3>
          <form>
            {Object.keys(data).map((key) => (
              !readonlyFields.includes(key) ? (
                <div key={key} className="mb-4">
                  <label className="block text-gray-700 capitalize">{key}</label>
                  <input
                    type="text"
                    name={key}
                    value={data[key] || ''}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
              ) : (
                <div key={key} className="mb-4">
                  <label className="block text-gray-700 capitalize">{key}</label>
                  <input
                    type="text"
                    name={key}
                    value={data[key] || ''}
                    readOnly
                    className="w-full p-2 border border-gray-300 rounded mt-1 bg-gray-100"
                  />
                </div>
              )
            ))}
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="bg-blue-600 text-white px-4 py-2 rounded"
                onClick={onSave}
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      );
}
