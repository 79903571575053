import React from 'react';
import { useData } from './DataContext';
import { IoReload } from "react-icons/io5";

export default function BotonActualizarData() {
    const { reloadData, loading } = useData();

    return (
        <button onClick={reloadData} className='h-full flex items-center justify-center'>
            {loading ? (
                <div style={loaderStyle}></div>
            ) : (
                <IoReload color='white' size={40} />
            )}
        </button>
    );
}

// Estilo en línea para el indicador de carga
const loaderStyle = {
    width: '50px',
    aspectRatio: '1',
    borderRadius: '50%',
    border: '8px solid',
    borderColor: '#fff #fff0',
    animation: 'l1 1s infinite',
};

// Keyframes para la animación de rotación
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}`, styleSheet.cssRules.length);
