import React, { useState } from 'react';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getApp } from "firebase/app"; 

export default function AgregarUsuariosFirebase() {
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Datos de los usuarios
  const usuarios = [
    { DOCUMENTO: "cindy_74_ecsa@gmail.com", correo: "cindy_74_ecsa@gmail.com", contraseña: "Y87B74", codigo: "742557", nombre: "Cindy Shaila", apellido: "Huamantuco Pacosonco", categoria: "Catequista", DNI: "74255734", celular: "", comunidad: "SAN MATEO" },
    { DOCUMENTO: "cristian_72_ecsa@gmail.com", correo: "cristian_72_ecsa@gmail.com", contraseña: "69021D", codigo: "728433", nombre: "Cristian", apellido: "Vilca", categoria: "catequista", DNI: "72843331", celular: "987670599", comunidad: "SAN MATEO" },
    { DOCUMENTO: "david_71_ecsa@gmail.com", correo: "david_71_ecsa@gmail.com", contraseña: "O810W6", codigo: "714444", nombre: "David", apellido: "Yucra Suasaca", categoria: "Catequista", DNI: "71444410", celular: "972465737", comunidad: "SAN MATEO" },
    { DOCUMENTO: "daysi_70_ecsa@gmail.com", correo: "daysi_70_ecsa@gmail.com", contraseña: "43125K", codigo: "703570", nombre: "Daysi Marleny", apellido: "Pari Gómez", categoria: "Catequista", DNI: "70357030", celular: "", comunidad: "" },
    { DOCUMENTO: "eber_71_ecsa@gmail.com", correo: "eber_71_ecsa@gmail.com", contraseña: "7O610H", codigo: "713491", nombre: "Eber Eddy", apellido: "Cruz Paredes", categoria: "Catequista", DNI: "71349108", celular: "951823086", comunidad: "SAN ANDRES" },
    { DOCUMENTO: "erik_70_ecsa@gmail.com", correo: "erik_70_ecsa@gmail.com", contraseña: "664Y4D", codigo: "701141", nombre: "Erik", apellido: "Calisaya Cari", categoria: "Catequista", DNI: "70114124", celular: "", comunidad: "SAN PEDRO" },
    { DOCUMENTO: "jhonathan_70_ecsa@gmail.com", correo: "jhonathan_70_ecsa@gmail.com", contraseña: "83B34J", codigo: "700935", nombre: "Jhonathan Daivis", apellido: "Barrantes Patina", categoria: "Catequista", DNI: "70093562", celular: "", comunidad: "SAN SANTIAGO" },
    { DOCUMENTO: "jorge_70_ecsa@gmail.com", correo: "jorge_70_ecsa@gmail.com", contraseña: "51P5E5", codigo: "702973", nombre: "Jorge Washington", apellido: "Valencia Flores", categoria: "Catequista", DNI: "70297364", celular: "", comunidad: "" },
    { DOCUMENTO: "juan_41_ecsa@gmail.com", correo: "juan_41_ecsa@gmail.com", contraseña: "F931T8", codigo: "418852", nombre: "Juan Carlos", apellido: "Mamani Moscayro", categoria: "Catequista", DNI: "41885272", celular: "", comunidad: "SAN ANDRES" },
    { DOCUMENTO: "karen_70_ecsa@gmail.com", correo: "karen_70_ecsa@gmail.com", contraseña: "T4I641", codigo: "704042", nombre: "Karen Yasmine", apellido: "Mayorga Paredes", categoria: "Catequista", DNI: "70404233", celular: "959547107", comunidad: "SANTO TOMAS" },
    { DOCUMENTO: "leslie_72_ecsa@gmail.com", correo: "leslie_72_ecsa@gmail.com", contraseña: "J74461", codigo: "723962", nombre: "Leslie Iskra", apellido: "Limachi Flores", categoria: "Catequista", DNI: "72396246", celular: "963686707", comunidad: "SAN JUAN" },
    { DOCUMENTO: "marco_74_ecsa@gmail.com", correo: "marco_74_ecsa@gmail.com", contraseña: "465K7Z", codigo: "740346", nombre: "Marco", apellido: "Ramos Paredes", categoria: "Catequista", DNI: "74034660", celular: "", comunidad: "" },
    { DOCUMENTO: "mayda_74_ecsa@gmail.com", correo: "mayda_74_ecsa@gmail.com", contraseña: "V28K79", codigo: "748790", nombre: "Mayda Elida", apellido: "Machaca Ccasa", categoria: "Catequista", DNI: "74879019", celular: "", comunidad: "" },
    { DOCUMENTO: "paúl_70_ecsa@gmail.com", correo: "paúl_70_ecsa@gmail.com", contraseña: "27B521", codigo: "704782", nombre: "Paúl Gianmarco", apellido: "Ponce Pastor", categoria: "Catequista", DNI: "70478275", celular: "", comunidad: "SAN JUAN" },
    { DOCUMENTO: "sheyla_75_ecsa@gmail.com", correo: "sheyla_75_ecsa@gmail.com", contraseña: "L64G48", codigo: "759932", nombre: "Sheyla Liss", apellido: "Chambilla Cruz", categoria: "Catequista", DNI: "75993262", celular: "914002617", comunidad: "SAN FELIPE" },
    { DOCUMENTO: "terry_70_ecsa@gmail.com", correo: "terry_70_ecsa@gmail.com", contraseña: "35M722", codigo: "708190", nombre: "Terry Jeferson", apellido: "Rengifo Ortiz", categoria: "Catequista", DNI: "70819094", celular: "", comunidad: "" },
    { DOCUMENTO: "alan_60_ecsa@gmail.com", correo: "alan_60_ecsa@gmail.com", contraseña: "P5H012", codigo: "605362", nombre: "Alan", apellido: "Ticona Ticona", categoria: "Iniciado", DNI: "60536261", celular: "", comunidad: "" },
    { DOCUMENTO: "ana_76_ecsa@gmail.com", correo: "ana_76_ecsa@gmail.com", contraseña: "99S19W", codigo: "761707", nombre: "Ana Madeley", apellido: "Yana Yucra", categoria: "Iniciado", DNI: "76170703", celular: "988780405", comunidad: "" },
    { DOCUMENTO: "anali_71_ecsa@gmail.com", correo: "anali_71_ecsa@gmail.com", contraseña: "686L6R", codigo: "711641", nombre: "Anali Milagros", apellido: "Ancco Lupaca", categoria: "Iniciado", DNI: "71164163", celular: "", comunidad: "" },
    { DOCUMENTO: "andrea_78_ecsa@gmail.com", correo: "andrea_78_ecsa@gmail.com", contraseña: "2UN791", codigo: "782869", nombre: "Andrea De Los Angeles", apellido: "Paquita Araujo", categoria: "Iniciado", DNI: "78286976", celular: "", comunidad: "" },
    { DOCUMENTO: "angie_60_ecsa@gmail.com", correo: "angie_60_ecsa@gmail.com", contraseña: "30135U", codigo: "603403", nombre: "Angie Virginia", apellido: "Tapia Hualpa", categoria: "Iniciado", DNI: "60340329", celular: "", comunidad: "" },
    { DOCUMENTO: "carlos_76_ecsa@gmail.com", correo: "carlos_76_ecsa@gmail.com", contraseña: "2B2591", codigo: "761836", nombre: "Carlos Froilán", apellido: "Ccopa Caira", categoria: "Iniciado", DNI: "76183698", celular: "", comunidad: "" },
    { DOCUMENTO: "clara_75_ecsa@gmail.com", correo: "clara_75_ecsa@gmail.com", contraseña: "N631U1", codigo: "750946", nombre: "Clara Isabel", apellido: "Gallegos Quisca", categoria: "Iniciado", DNI: "75094614", celular: "955525327", comunidad: "" },
    { DOCUMENTO: "cristhian_60_ecsa@gmail.com", correo: "cristhian_60_ecsa@gmail.com", contraseña: "8CT822", codigo: "602518", nombre: "Cristhian Edgar", apellido: "Quispe Dávila", categoria: "Iniciado", DNI: "60251825", celular: "", comunidad: "" },
    { DOCUMENTO: "dany_60_ecsa@gmail.com", correo: "dany_60_ecsa@gmail.com", contraseña: "L7C744", codigo: "605635", nombre: "Dany Milagros", apellido: "Carbajal Flores", categoria: "Iniciado", DNI: "60563569", celular: "", comunidad: "" },
    { DOCUMENTO: "gimena_61_ecsa@gmail.com", correo: "gimena_61_ecsa@gmail.com", contraseña: "1064SP", codigo: "611941", nombre: "Gimena Belén", apellido: "Mayta Fernández", categoria: "Iniciado", DNI: "61194156", celular: "", comunidad: "" },
    { DOCUMENTO: "gustavo_73_ecsa@gmail.com", correo: "gustavo_73_ecsa@gmail.com", contraseña: "2QP313", codigo: "738558", nombre: "Gustavo Rocky", apellido: "Ramos Colque", categoria: "Iniciado", DNI: "73855809", celular: "", comunidad: "" },
    { DOCUMENTO: "jhoselin_74_ecsa@gmail.com", correo: "jhoselin_74_ecsa@gmail.com", contraseña: "8D891Z", codigo: "748687", nombre: "Jhoselin Milene", apellido: "Pancca Coila", categoria: "Iniciado", DNI: "74868786", celular: "", comunidad: "" },
    { DOCUMENTO: "jose_60_ecsa@gmail.com", correo: "jose_60_ecsa@gmail.com", contraseña: "33T6S1", codigo: "607590", nombre: "Jose Enrique", apellido: "Coaguila Alave", categoria: "Iniciado", DNI: "60759097", celular: "", comunidad: "" },
    { DOCUMENTO: "joseph_61_ecsa@gmail.com", correo: "joseph_61_ecsa@gmail.com", contraseña: "530W07", codigo: "610638", nombre: "Joseph Iojairo", apellido: "Canaza Macedo", categoria: "Iniciado", DNI: "61063890", celular: "936585000", comunidad: "" },
    { DOCUMENTO: "leydi_71_ecsa@gmail.com", correo: "leydi_71_ecsa@gmail.com", contraseña: "M4999D", codigo: "711718", nombre: "Leydi Mariela", apellido: "Checalla Pacho", categoria: "Iniciado", DNI: "71171839", celular: "", comunidad: "SAN PEDRO" },
    { DOCUMENTO: "magdalena_73_ecsa@gmail.com", correo: "magdalena_73_ecsa@gmail.com", contraseña: "I881O2", codigo: "732546", nombre: "Magdalena", apellido: "Choquegonza Carrillo", categoria: "Iniciado", DNI: "73254600", celular: "", comunidad: "" },
    { DOCUMENTO: "rocio_74_ecsa@gmail.com", correo: "rocio_74_ecsa@gmail.com", contraseña: "9O6203", codigo: "747252", nombre: "Rocio", apellido: "Catari Villanueva", categoria: "Iniciado", DNI: "74725292", celular: "", comunidad: "" },
    { DOCUMENTO: "sebastian_61_ecsa@gmail.com", correo: "sebastian_61_ecsa@gmail.com", contraseña: "Z8D904", codigo: "612051", nombre: "Sebastian Saul", apellido: "Ramos Gomez", categoria: "Iniciado", DNI: "61205114", celular: "", comunidad: "" },
    { DOCUMENTO: "sulenca_72_ecsa@gmail.com", correo: "sulenca_72_ecsa@gmail.com", contraseña: "N6P978", codigo: "728342", nombre: "Sulenca elitte", apellido: "Llanos Ccopa", categoria: "Iniciado", DNI: "72834254", celular: "", comunidad: "" },
    { DOCUMENTO: "yhoseph_61_ecsa@gmail.com", correo: "yhoseph_61_ecsa@gmail.com", contraseña: "4302RI", codigo: "612", nombre: "Yhoseph anderson", apellido: "Mendoza Flores", categoria: "Iniciado", DNI: "612", celular: "", comunidad: "" },
    { DOCUMENTO: "zeider_71_ecsa@gmail.com", correo: "zeider_71_ecsa@gmail.com", contraseña: "74GS00", codigo: "713607", nombre: "Zeider", apellido: "Guillen Apaza", categoria: "Iniciado", DNI: "71360774", celular: "", comunidad: "" },
    { DOCUMENTO: "ana_78_ecsa@gmail.com", correo: "ana_78_ecsa@gmail.com", contraseña: "D1122G", codigo: "787208", nombre: "Ana Maribel", apellido: "Illacutipa Mamani", categoria: "Pre Catequista", DNI: "7872081", celular: "924772042", comunidad: "" },
    { DOCUMENTO: "angela_71_ecsa@gmail.com", correo: "angela_71_ecsa@gmail.com", contraseña: "379D0L", codigo: "717876", nombre: "Angela Daniela", apellido: "Condori Mosqueta", categoria: "Pre Catequista", DNI: "71787633", celular: "948058874", comunidad: "SAN ANDRES" },
    { DOCUMENTO: "cristian_77_ecsa@gmail.com", correo: "cristian_77_ecsa@gmail.com", contraseña: "U396U7", codigo: "774252", nombre: "Cristian Ubaldo", apellido: "Coaquira Mamani", categoria: "Pre Catequista", DNI: "77425277", celular: "", comunidad: "" },
    { DOCUMENTO: "eylem_62_ecsa@gmail.com", correo: "eylem_62_ecsa@gmail.com", contraseña: "U2Z539", codigo: "628103", nombre: "Eylem", apellido: "Quispe Lujano", categoria: "Pre Catequista", DNI: "62810385", celular: "956417669", comunidad: "SAN MATEO" },
    { DOCUMENTO: "fabrizio_71_ecsa@gmail.com", correo: "fabrizio_71_ecsa@gmail.com", contraseña: "47T30I", codigo: "714375", nombre: "Fabrizio", apellido: "Flores Ramos", categoria: "Pre Catequista", DNI: "71437589", celular: "", comunidad: "SAN FELIPE" },
    { DOCUMENTO: "harry_72_ecsa@gmail.com", correo: "harry_72_ecsa@gmail.com", contraseña: "89K38G", codigo: "729485", nombre: "Harry Leomar", apellido: "Velasquez Durand", categoria: "Pre Catequista", DNI: "72948526", celular: "", comunidad: "SAN FELIPE" },
    { DOCUMENTO: "karol_75_ecsa@gmail.com", correo: "karol_75_ecsa@gmail.com", contraseña: "399X0D", codigo: "754129", nombre: "Karol Rubi", apellido: "Apaza Villasante", categoria: "Pre Catequista", DNI: "75412925", celular: "", comunidad: "SAN SANTIAGO" },
    { DOCUMENTO: "maria_74_ecsa@gmail.com", correo: "maria_74_ecsa@gmail.com", contraseña: "H785I4", codigo: "744218", nombre: "Maria Liz", apellido: "Mamani Mamani", categoria: "Pre Catequista", DNI: "74421821", celular: "", comunidad: "SANTO TOMAS" },
    { DOCUMENTO: "mayled_60_ecsa@gmail.com", correo: "mayled_60_ecsa@gmail.com", contraseña: "WY5518", codigo: "608502", nombre: "Mayled", apellido: "Calisaya Cari", categoria: "Pre Catequista", DNI: "60850276", celular: "", comunidad: "SAN FELIPE" },
    { DOCUMENTO: "midian_76_ecsa@gmail.com", correo: "midian_76_ecsa@gmail.com", contraseña: "1J5M50", codigo: "768529", nombre: "MIDIAN FRANCINE", apellido: "GUTIERREZ QUISPE", categoria: "Pre Catequista", DNI: "76852995", celular: "", comunidad: "" },
    { DOCUMENTO: "pablo_75_ecsa@gmail.com", correo: "pablo_75_ecsa@gmail.com", contraseña: "60092B", codigo: "756097", nombre: "Pablo Manuel", apellido: "Quispe Hanco", categoria: "Pre Catequista", DNI: "75609709", celular: "", comunidad: "SAN MATEO" },
    { DOCUMENTO: "sahun_71_ecsa@gmail.com", correo: "sahun_71_ecsa@gmail.com", contraseña: "Z208E9", codigo: "713490", nombre: "Sahun Fernando", apellido: "Maquera Flores", categoria: "Pre Catequista", DNI: "71349059", celular: "", comunidad: "" },
    { DOCUMENTO: "zulema_75_ecsa@gmail.com", correo: "zulema_75_ecsa@gmail.com", contraseña: "Q10P26", codigo: "755160", nombre: "Zulema Guadalupe", apellido: "Mamani Ayamamani", categoria: "Pre-Catequista", DNI: "75516087", celular: "", comunidad: "" },
  ];

  // Función para agregar documentos a Firestore
  const agregarUsuarios = async () => {
    const db = getFirestore(getApp());

    try {
      for (const usuario of usuarios) {
        const usuarioDoc = doc(db, 'usuarios', usuario.DOCUMENTO);
        const { DOCUMENTO, ...campos } = usuario; // Excluye DOCUMENTO y usa los demás campos
        await setDoc(usuarioDoc, campos);
      }
      alert('Usuarios agregados correctamente');
    } catch (error) {
      console.error("Error al agregar los usuarios: ", error);
    }
  };

  return (
    <div className='pt-20 p-6 w-full flex flex-col items-center'>
    <div className='overflow-x-auto w-full'>
      <table className='min-w-full bg-white border border-gray-300'>
        <thead className='bg-gray-100'>
          <tr>
            {Object.keys(usuarios[0]).map((key) => (
              <th className='border-b border-gray-300 p-3 text-left text-gray-600' key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario) => (
            <tr key={usuario.DOCUMENTO} className='hover:bg-gray-50'>
              {Object.values(usuario).map((value, index) => (
                <td className='border-b border-gray-300 p-3 text-gray-800' key={index}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className='mt-6'>
      <button className='bg-red-600 p-2 text-white rounded-lg shadow-md hover:bg-red-700 transition' onClick={() => setConfirmOpen(true)}>
        Agregar Usuarios
      </button>
    </div>
    {confirmOpen && (
      <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
        <div className='bg-white p-6 rounded-lg shadow-lg'>
          <p className='mb-4 text-lg font-semibold'>¿Estás seguro de que deseas agregar todos los usuarios?</p>
          <div className='flex justify-end gap-2'>
            <button className='bg-green-500 p-2 text-white rounded-lg shadow-md hover:bg-green-600 transition' onClick={() => { agregarUsuarios(); setConfirmOpen(false); }}>
              Sí
            </button>
            <button className='bg-gray-500 p-2 text-white rounded-lg shadow-md hover:bg-gray-600 transition' onClick={() => setConfirmOpen(false)}>
              No
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  );
}
