import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaQrcode, FaCalendarAlt } from 'react-icons/fa';
import { IoTime } from 'react-icons/io5';
import { useData } from '../DataContext';
import axios from 'axios';
import QrScaner from '../secretaria/asistencia/componentes/QrScaner';

const AsistenciaSalidaQrPage = () => {
  const location = useLocation();
  const { listaGeneral, listaCatequistas } = useData(); // Agrega listaCatequistas
  const [qrResult, setQrResult] = useState(location.state?.qrResult || '');
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isQrVisible, setIsQrVisible] = useState(false);
  const [observacion, setObservacion] = useState('');


  const [currentTime, setCurrentTime] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [data, setData] = useState({
    NOMBRES: '',
    APELLIDOS: '',
    COMUNIDAD: '',
    CATEGORIA: '', // Agrega el campo de categoría,
OBSERVACION:''
  });

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const now = new Date();
    const time = now.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const date = now.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    setCurrentTime(time);
    setCurrentDate(date);
  }, []);

  useEffect(() => {
    if (qrResult) {
      let foundData = listaGeneral.find((item) => item.ID_CONFIRMANDO === parseInt(qrResult));
      if (foundData) {
        setData({
          NOMBRES: foundData.NOMBRES,
          APELLIDOS: foundData.APELLIDOS,
          COMUNIDAD: foundData.COMUNIDAD,  // Asigna el valor de COMUNIDAD
          CATEGORIA: 'CONFIRMANDO',
          OBSERVACION: foundData.OBSERVACION || ''  // Asegura que OBSERVACION se maneje para ambos casos
        });
      } else {
        foundData = listaCatequistas.find((item) => item.ID_CATEQUISTA === parseInt(qrResult));
        if (foundData) {
          setData({
            NOMBRES: foundData.NOMBRES,
            APELLIDOS: foundData.APELLIDOS,
            COMUNIDAD: foundData.CATEGORIA,  // Asigna el valor de CATEGORIA como COMUNIDAD
            CATEGORIA: 'CATEQUISTA',
            OBSERVACION:foundData.OBSERVACION
          });
        } else {
          console.error('No se encontraron datos para el ID proporcionado');
        }
      }
    }
  }, [qrResult, listaGeneral, listaCatequistas]);

  const markAttendance = async () => {
    try {
      let endpoint = '';
      const requestData = {
        NOMBRES: data.NOMBRES,
        APELLIDOS: data.APELLIDOS,
        HORA: currentTime,
        FECHA: currentDate,
        ESTADO: "SALIDA",
        OBSERVACION: observacion  // Esto debería tomar el valor del estado actualizado de la observación
      };
      console.log(observacion)
      
  
      console.log('Datos enviados:', requestData); // Verificar que los datos se envían correctamente
  
      if (data.CATEGORIA === 'CONFIRMANDO') {
        endpoint = `${backendUrl}/confirmandos_asistencia`;
        requestData.ID_CONFIRMANDO = qrResult;
        requestData.COMUNIDAD = data.COMUNIDAD;
      } else if (data.CATEGORIA === 'CATEQUISTA') {
        endpoint = `${backendUrl}/catequistas_asistencia`;
        requestData.ID_CATEQUISTA = qrResult;
        requestData.CATEGORIA = data.COMUNIDAD;
      } else {
        console.error('Categoría no reconocida');
        return;
      }
  
      const response = await axios.post(endpoint, requestData);
  
      alert(response.data.message);
      resetComponent();
    } catch (error) {
      console.error('Error al marcar la asistencia:', error);
      alert('Error al marcar la asistencia');
    }
  };
  




  const handleInputChange = (event) => {
    setQrResult(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      let foundData = listaGeneral.find((item) => item.ID_CONFIRMANDO === parseInt(qrResult));
      if (foundData) {
        setData({
          NOMBRES: foundData.NOMBRES,
          APELLIDOS: foundData.APELLIDOS,
          COMUNIDAD: foundData.COMUNIDAD,  // Asigna el valor de COMUNIDAD
          CATEGORIA: 'CONFIRMANDO'
        });
      } else {
        foundData = listaCatequistas.find((item) => item.ID_CATEQUISTA === parseInt(qrResult));
        if (foundData) {
          setData({
            NOMBRES: foundData.NOMBRES,
            APELLIDOS: foundData.APELLIDOS,
            COMUNIDAD: foundData.CATEGORIA,  // Asigna el valor de CATEGORIA como COMUNIDAD
            CATEGORIA: 'CATEQUISTA'
          });
        } else {
          console.error('No se encontraron datos para el ID proporcionado');
        }
      }
    }
  };

  useEffect(() => {
    const { NOMBRES, APELLIDOS } = data;
    setIsButtonVisible(NOMBRES && APELLIDOS);
  }, [data]);

  const resetComponent = () => {
    setQrResult('');
    setData({
      NOMBRES: '',
      APELLIDOS: '',
      COMUNIDAD: '',
      CATEGORIA: ''
    });
    const now = new Date();
    const time = now.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const date = now.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    setCurrentTime(time);
    setCurrentDate(date);
  };

  const toggleQrScanner = () => {
    setIsQrVisible(!isQrVisible);
  };

  const handleScanResult = (result) => {
    setQrResult(result);
    setIsQrVisible(false);
  };

  return (
    <div className='w-screen'>
      <div className='flex flex-col text-black mt-24 w-full items-center space-y-6 px-5'>
        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
          <div>ID QR</div>
          <div className='flex items-center'>
            <input
              className='border-2 rounded-md h-12'
              value={qrResult}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            <a className='px-2' onClick={toggleQrScanner}>
              <FaQrcode />
            </a>
          </div>
        </div>

        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
          <div>HORA</div>
          <div className='flex items-center'>
            <input className='border-2 rounded-md h-12' value={currentTime} readOnly />
            <div className='px-2'>
              <IoTime />
            </div>
          </div>
        </div>

        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
          <div>FECHA</div>
          <div className='flex items-center'>
            <input className='border-2 rounded-md h-12' value={currentDate} readOnly />
            <div className='px-2'>
              <FaCalendarAlt />
            </div>
          </div>
        </div>

        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
          <div>NOMBRE</div>
          <div className='flex items-center'>
            <input className='border-2 rounded-md h-12' value={data.NOMBRES} readOnly />
            <div className='px-2'>
              <FaCalendarAlt />
            </div>
          </div>
        </div>

        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
          <div>APELLIDO</div>
          <div className='flex items-center'>
            <input className='border-2 rounded-md h-12' value={data.APELLIDOS} readOnly />
            <div className='px-2'>
              <FaCalendarAlt />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
          <div>CATEGORIA</div>
          <div className='flex items-center'>
            <input className='border-2 rounded-md h-12' value={data.CATEGORIA} readOnly />
            <div className='px-2'>
              <FaCalendarAlt />
            </div>
          </div>
        </div>

        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
          <div>COMUNIDAD</div>
          <div className='flex items-center'>
            <input className='border-2 rounded-md h-12' value={data.COMUNIDAD} readOnly />
            <div className='px-2'>
              <FaCalendarAlt />
            </div>
          </div>
        </div>
        <div className='flex flex-row w-full justify-between space-x-5 items-center'>
  <div>OBSERVACIÓN</div>
  <div className='flex items-center'>
    <textarea
      className='border-2 rounded-md h-24 w-full' // Cambia el tamaño para más texto
      value={observacion}
      onChange={(e) => setObservacion(e.target.value)} // Actualiza el estado de la observación
    />
    <div className='px-2'>
      <FaCalendarAlt />
    </div>
  </div>
</div>



        <button
          className={`bg-red-700 text-white p-4 rounded-lg ${isButtonVisible ? '' : 'hidden'}`}
          onClick={markAttendance}
        >
          MARCAR ASISTENCIA
        </button>
      </div>

      {isQrVisible && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-75'>
          <QrScaner onScanResult={handleScanResult} />
        </div>
      )}
    </div>
  );
};

export default AsistenciaSalidaQrPage;
