import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useData } from '../../DataContext';

export default function CatequistaAsistencia() {
    const { usuario, loading } = useData();
    const [filtradasAsistencia, setFiltradasAsistencia] = useState([]);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchAsistencia = async () => {
            try {
                const response = await axios.get(`${backendUrl}/catequistas_asistencia`);
                const asistenciaData = response.data;
                console.log(asistenciaData);

                if (usuario && asistenciaData.length > 0) {
                    const codigoUsuario = usuario.codigo; // Usa el código del usuario desde el contexto

                    console.log(usuario);
                    console.log(codigoUsuario);

                    const asistenciaFiltrada = asistenciaData.filter(
                        (asistencia) => asistencia.ID_CATEQUISTA.toString() === codigoUsuario.toString()
                    );
                    setFiltradasAsistencia(asistenciaFiltrada);
                }
            } catch (error) {
                console.error('Error al obtener los datos de asistencia:', error);
            }
        };

        fetchAsistencia();
    }, [usuario, backendUrl]);

    if (loading) {
        return <div className="text-center p-4">Loading...</div>;
    }

    return (
        <div className='pt-24 px-6'>
            <h1 className='text-2xl font-bold mb-6 text-center text-gray-800'>Mi Asistencia</h1>
            {filtradasAsistencia.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">ID CATEQUISTA</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Nombres</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Apellidos</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Categoría</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Hora</th>
                                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtradasAsistencia.map((asistencia) => (
                                <tr key={asistencia.ID_ASISTENCIA} className="border-b hover:bg-gray-50">
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.ID_CATEQUISTA}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.NOMBRES}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.APELLIDOS}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.CATEGORIA}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.HORA}</td>
                                    <td className="px-4 py-2 text-sm text-gray-800">{asistencia.FECHA}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="text-center text-gray-600">No hay registros de asistencia disponibles.</div>
            )}
        </div>
    );
}
