import React from 'react';
import { useData } from './DataContext';

export default function Prueba() {
    const { listaGeneral, asistenciaGeneral, usuario, loading } = useData(); // Obtén los datos desde el contexto

    // Asegúrate de que los valores sean cadenas para la comparación
    const asistencia = asistenciaGeneral.find(a =>
        a.ID_GENERAL === "24001" &&
        a.FECHA === "20/4/2024");

    return (
        <div className='pt-24'>
            {asistencia ? (
                <div>
                    <h1>Asistencia Encontrada:</h1>
                    <p>Nombres: {asistencia.NOMBRES}</p>
                    <p>Apellidos: {asistencia.APELLIDOS}</p>
                    <p>Hora: {asistencia.HORA}</p>
                </div>
            ) : (
                <p>No se encontró la asistencia.</p>
            )}
        </div>
    );
}
