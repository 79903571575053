import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormularioDinamico from '../FormularioDinamico';

export default function ListaCatequistas() {
  const [listaCatequistas, setListaCatequistas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingCatequista, setEditingCatequista] = useState(null); 
  const [showModal, setShowModal] = useState(false); 
  const [formValues, setFormValues] = useState({});
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(`${backendUrl}/catequistas`)
      .then((response) => {
        const filteredCatequistas = response.data.filter(catequista => catequista.CATEGORIA === 'Catequista');
        setListaCatequistas(filteredCatequistas);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener los catequistas:', error);
        setLoading(false);
      });
  }, [backendUrl]);

  const handleEditClick = (catequista) => {
    setEditingCatequista(catequista);
    setFormValues(catequista);
    setShowModal(true);
  };

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(`${backendUrl}/catequistas/${formValues.ID_CATEQUISTA}`, formValues);
      console.log('Actualización exitosa:', response.data);
      setShowModal(false);
      alert('¡Los cambios fueron guardados exitosamente!');
      setListaCatequistas((prevLista) =>
        prevLista.map((catequista) =>
          catequista.ID_CATEQUISTA === formValues.ID_CATEQUISTA ? formValues : catequista
        )
      );
    } catch (error) {
      console.error('Error al actualizar:', error);
      alert('Hubo un error al guardar los cambios.');
    }
  };

  const handleCancelClick = () => {
    setShowModal(false);
    setEditingCatequista(null);
  };

  if (loading) {
    return <div className="text-center mt-5">Cargando...</div>;
  }

  return (
    <div className="p-5">
      <h2 className="text-2xl font-bold mb-5 text-center">Lista de Catequistas</h2>
      {listaCatequistas.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-blue-600 text-white">
              <tr>
                <th className="py-3 px-4 text-left">ID</th>
                <th className="py-3 px-4 text-left">Nombres</th>
                <th className="py-3 px-4 text-left">Apellidos</th>
                <th className="py-3 px-4 text-left">Categoría</th>
                <th className="py-3 px-4 text-left">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {listaCatequistas.map((catequista) => (
                <tr key={catequista.ID_CATEQUISTA} className="border-b hover:bg-gray-100">
                  <td className="py-3 px-4">{catequista.ID_CATEQUISTA}</td>
                  <td className="py-3 px-4">{catequista.NOMBRES}</td>
                  <td className="py-3 px-4">{catequista.APELLIDOS}</td>
                  <td className="py-3 px-4">{catequista.CATEGORIA}</td>
                  <td className="py-3 px-4">
                    <button
                      className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                      onClick={() => handleEditClick(catequista)}
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-5">No se encontraron catequistas.</p>
      )}

      {showModal && editingCatequista && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <FormularioDinamico
            data={formValues}
            onChange={handleFormChange}
            onSave={handleSaveClick}
            onCancel={handleCancelClick}
            readonlyFields={['CATEGORIA']} // Aquí puedes definir los campos que no quieres que se editen
          />
        </div>
      )}
    </div>
  );
}
