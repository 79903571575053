import React, { useEffect, useState } from 'react';


export default function AsistenciaGeneralPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  console.log('Backend URL:', backendUrl);  // Verifica que esto muestre la URL correcta
  
  // Función para cargar los datos desde el backend
  const fetchData = () => {
    fetch(`${backendUrl}/confirmandos_asistencia`)
      .then((response) => response.json())
      .then((data) => {
        const reversedData = [...data].reverse();
        setData(reversedData);
        setLoading(false);
      })
      .catch((error) => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setShowModal(true);
  };

  const confirmDelete = () => {
    fetch(`${backendUrl}/confirmandos_asistencia/${rowToDelete.ID_ASISTENCIA}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);  // Verifica la respuesta del backend
        if (data.success) {
          setData((prevData) => prevData.filter((item) => item.ID_ASISTENCIA !== rowToDelete.ID_ASISTENCIA));
          setShowModal(false);
        } else {
          console.error('Error al eliminar:', data.error || 'Operación fallida');
        }
      })
      .catch((error) => console.error('Error en la petición de eliminación:', error));
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className='flex w-full'>
      <div className='mt-24 w-full px-4'>
        <h1 className='text-2xl font-bold mb-4'>Asistencia General</h1>
        <table className='min-w-full bg-white border border-gray-300'>
          <thead>
            <tr>
              <th className='px-4 py-2 border-b'>ID_GENERAL</th>
              <th className='px-4 py-2 border-b'>NOMBRES</th>
              <th className='px-4 py-2 border-b'>APELLIDOS</th>
              <th className='px-4 py-2 border-b'>COMUNIDAD</th>
              <th className='px-4 py-2 border-b'>HORA</th>
              <th className='px-4 py-2 border-b'>FECHA</th>
              <th className='px-4 py-2 border-b'>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.ID_ASISTENCIA}>
                <td className='px-4 py-2 border-b'>{row.ID_CONFIRMANDO}</td>
                <td className='px-4 py-2 border-b'>{row.NOMBRES}</td>
                <td className='px-4 py-2 border-b'>{row.APELLIDOS}</td>
                <td className='px-4 py-2 border-b'>{row.COMUNIDAD}</td>
                <td className='px-4 py-2 border-b'>{row.HORA}</td>
                <td className='px-4 py-2 border-b'>{row.FECHA}</td>
                <td className='px-4 py-2 border-b'>
                  <button className='bg-red-500 text-white px-3 py-1 rounded' onClick={() => handleDeleteClick(row)}>
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center'>
          <div className='bg-white p-6 rounded shadow-lg'>
            <h2 className='text-xl font-bold mb-4'>¿Estás seguro de que quieres eliminar esta fila?</h2>
            <div className='flex justify-end'>
              <button className='bg-gray-300 text-black px-4 py-2 rounded mr-2' onClick={() => setShowModal(false)}>
                Cancelar
              </button>
              <button className='bg-red-500 text-white px-4 py-2 rounded' onClick={confirmDelete}>
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
