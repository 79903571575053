import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, doc, setDoc, deleteDoc, collection, getDocs } from "firebase/firestore"; // Importa Firestore y sus funciones

const firebaseConfig = {
  apiKey: "AIzaSyB0_anq9nceZMEetdcY5vzzft1TtEQ1qcs",
  authDomain: "ecsaapp2024.firebaseapp.com",
  projectId: "ecsaapp2024",
  storageBucket: "ecsaapp2024.appspot.com",
  messagingSenderId: "235353406416",
  appId: "1:235353406416:web:989c268255abddc1df97d5",
  measurementId: "G-4YXT6Y14ES"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Inicializa Auth y Google provider
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();

// Inicializa Firestore
const db = getFirestore(app);

// Función para cargar usuarios
const loadUsers = async () => {
  const usersCollection = collection(db, 'usuarios');
  const userSnapshot = await getDocs(usersCollection);
  return userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Función para actualizar un usuario
const actualizarUsuario = async (usuario) => {
  try {
    await setDoc(doc(db, 'usuarios', usuario.id), usuario);
    return true;
  } catch (error) {
    console.error("Error al actualizar el usuario: ", error);
    return false;
  }
};

// Función para eliminar un usuario
const eliminarUsuario = async (id) => {
  try {
    await deleteDoc(doc(db, 'usuarios', id));
    return true;
  } catch (error) {
    console.error("Error al eliminar el usuario: ", error);
    return false;
  }
};

// Función para actualizar las categorías
const actualizarCategoria = async (categoria, data) => {
  try {
    const categoriaRef = doc(db, 'categoria', categoria);
    await setDoc(categoriaRef, data, { merge: true });
    return true;
  } catch (error) {
    console.error("Error actualizando categoría:", error);
    return false;
  }
};

export { auth, googleAuthProvider, analytics, db, loadUsers, actualizarUsuario, eliminarUsuario, actualizarCategoria };
