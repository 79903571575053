import React from 'react';
import Lottie from 'react-lottie';

function LottieAnimation({ nombre }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require(`../assets/Animations/${nombre}.json`), // Asegúrate de que el path sea correcto
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="h-full w-full">
      <Lottie options={defaultOptions} />
    </div>
  );
}

export default LottieAnimation;
