import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import InicioSesionPage from './pages/sesion/InicioSesionPage';
import { auth } from './firebase';
import { DataProvider } from './pages/DataContext';
import { getDocs, collection } from 'firebase/firestore';
import { db } from './firebase';
import HomeCatequista from './pages/catequista/homeCatequista/HomeCatequista';
import HomeComunidad from './pages/comunidad/homeComunidad/HomeComunidad';
import HomeConfirmando from './pages/confirmando/homeConfirmando/HomeConfirmando';
import CatequistaOpcion from './pages/catequista/Catequista';
import AdminOpcion from './pages/admin/Admin';
import ComunidadOpcion from './pages/comunidad/Comunidad';
import ConfirmandoOpcion from './pages/confirmando/Confirmando';
import SecretariaOpcion from './pages/secretaria/Secretaria';
import HomeAdmin from './pages/admin/homeAdmin/HomeAdmin';
import HomeSecretaria from './pages/secretaria/homeSecretaria/HomeSecretaria';
import Sidebar from './pages/Sidebar';
import AsistenciaQrPage from './pages/secretaria/asistencia/AsistenciaQrPage';
import EsperaPage from './pages/EsperaPage';
import AsistenciaGeneralPage from './pages/secretaria/asistencia/AsistenciaGeneralPage';
import QrScanPage from './pages/secretaria/asistencia/componentes/QrScanPage';
import AsistenciaConfirmando from './pages/confirmando/asistenciaConfirmando/AsistenciaConfirmando';
import ComunidadConfirmando from './pages/confirmando/comunidadConfirmando/ComunidadConfirmando';
import HomePage from './pages/comunidad/homeComunidad/HomePage';
import MiComunidad from './pages/comunidad/micomunidad/MiComunidad';
import AsistenciaMiComunidad from './pages/comunidad/asistenciaMiComunidad/AsistenciaMIComunidad';
import Prueba from './pages/Prueba';
import InicioSesionCorreo from './pages/sesion/InicioSesionCorreo';
import Formador from './pages/formador/Formador';
import ListaCatequistas from './pages/formador/formador_catequistas/ListaCatequistas';
import CatequistasEstado from './pages/formador/formador_catequistas/CatequistaEstado';
import CatequistasEstadoTodos from './pages/catequista/CatequistasEstadoTodos';
import CatequistaAsitencia from './pages/catequista/CatequistaAsistencia/CatequistaAsistencia';
import CatequistaPerfil from './pages/catequista/CatequistaPerfil/CatequistaPerfil';
import AgregarUsuariosFirebase from './pages/admin/AgregarUsuariosFirebase';
import ListaPrecatequistas from './pages/formador/formador_precatequistas/ListaPrecatequistas';
import ListaIniciados from './pages/formador/formandor_iniciados/ListaIniciados';
import IniciadosEstado from './pages/formador/formandor_iniciados/IniciadosEstado';
import PrecatequistasEstado from './pages/formador/formador_precatequistas/PrecatequistasEstado';
import AsistenciaSalidaQrPage from './pages/formador/AsistenciaSalidaQrPage';
import RegistrarUsuarios from './pages/admin/RegistrarUsuarios';
import EditarUsuariosFirebase from './pages/admin/EditarUsuariosFirebase';
import AgregarCategorias from './pages/admin/AgregarCategorias';
import AsistenciaEntradaQrPage from './pages/formador/AsistenciaEntradaQrPage';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        const roles = await cargarRolesDisponibles(user.email);
        setUserRoles(roles);
      } else {
        setCurrentUser(null);
        setUserRoles([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const cargarRolesDisponibles = async (email) => {
    try {
      const roles = [];
      const categoriasSnapshot = await getDocs(collection(db, 'categoria'));

      categoriasSnapshot.forEach((doc) => {
        const data = doc.data();
        for (const key in data) {
          if (data[key] === email) {
            roles.push(doc.id); // Agrega el nombre del documento (categoría) al array de roles disponibles
          }
        }
      });

      return roles;
    } catch (error) {
      console.error('Error al cargar las categorías.', error);
      return [];
    }
  };

  if (loading) {
    return <EsperaPage />;
  }

  const ProtectedRoute = ({ element, role }) => {
    const location = useLocation();
    if (userRoles.includes(role)) {
      return element;
    } else {
      return <Navigate to={location.pathname} />;
    }
  };

  const shouldShowSidebar = currentUser && userRoles.length > 0;

  return (
    <DataProvider>
      <Router>
        {shouldShowSidebar && <Sidebar />}

        <Routes>

          <Route
            path="/"
            element={
              currentUser && userRoles.length > 0
                ? <Navigate to={`/${userRoles[0]}`} />
                : <InicioSesionCorreo availableRoles={userRoles} />
            }
          />
          <Route path="/admin" element={<ProtectedRoute role="admin" element={<HomeAdmin />} />} />
          <Route path="/agregar_usuarios" element={<ProtectedRoute role="admin" element={<AgregarUsuariosFirebase />} />} />
          <Route path="/registrar_usuarios" element={<ProtectedRoute role="admin" element={<RegistrarUsuarios />} />} />
          <Route path="/editar_usuarios" element={<ProtectedRoute role="admin" element={<EditarUsuariosFirebase />} />} />
          <Route path="/agregar_categorias" element={<ProtectedRoute role="admin" element={<AgregarCategorias />} />} />



          <Route path="/secretaria" element={<ProtectedRoute role="secretaria" element={<HomeSecretaria />} />} />
          <Route path="/secretaria/op" element={<ProtectedRoute role="secretaria" element={<SecretariaOpcion />} />} />
          <Route path="/asistencia_qr" element={<ProtectedRoute role="secretaria" element={<AsistenciaQrPage />} />} />
          <Route path="/asistencia_general" element={<ProtectedRoute role="secretaria" element={<AsistenciaGeneralPage />} />} />
          {/* <Route path="/qrds" element={<ProtectedRoute role="secretaria" element={<QrScanPage />} />} /> */}


          <Route path="/confirmando" element={<ProtectedRoute role="confirmando" element={<HomeConfirmando />} />} />
          <Route path="/comunidad_confirmando" element={<ProtectedRoute role="confirmando" element={<ComunidadConfirmando />} />} />
          <Route path="/asistencia_confirmando" element={<ProtectedRoute role="confirmando" element={<AsistenciaConfirmando />} />} />



          <Route path="/catequista" element={<ProtectedRoute role="catequista" element={<HomeCatequista />} />} />
          <Route path="/estado_catequistas" element={<ProtectedRoute role="catequista" element={<CatequistasEstadoTodos />} />} />
          <Route path="/catequista_asistencia" element={<ProtectedRoute role="catequista" element={<CatequistaAsitencia />} />} />
          <Route path="/catequista_perfil" element={<ProtectedRoute role="catequista" element={<CatequistaPerfil />} />} />



          <Route path="/comunidad" element={<ProtectedRoute role="comunidad" element={<HomeComunidad />} />} />
          <Route path="/mi_comunidad" element={<ProtectedRoute role="comunidad" element={<MiComunidad />} />} />
          <Route path="/asistencia_mi_comunidad" element={<ProtectedRoute role="comunidad" element={<AsistenciaMiComunidad />} />} />
          <Route path="/lista_general" element={<ProtectedRoute role="comunidad" element={<HomePage />} />} />
          <Route path="/comunidad/op" element={<ProtectedRoute role="comunidad" element={<ComunidadOpcion />} />} />

          <Route path="/formador" element={<ProtectedRoute role="formador_catequistas" element={<Formador />} />} />
          <Route path="/lista_catequistas" element={<ProtectedRoute role="formador_catequistas" element={<ListaCatequistas />} />} />
          <Route path="/catequista_estado" element={<ProtectedRoute role="formador_catequistas" element={<CatequistasEstado />} />} />
          <Route path="/asistencia_qr_catequistas" element={<ProtectedRoute role="formador_catequistas" element={<AsistenciaEntradaQrPage />} />} />
          <Route path="/asistencia_salida_qr_catequistas1" element={<ProtectedRoute role="formador_catequistas" element={<AsistenciaSalidaQrPage />} />} />


          <Route path="/formador" element={<ProtectedRoute role="formador_pre_catequistas" element={<Formador />} />} />
          <Route path="/lista_precatequistas" element={<ProtectedRoute role="formador_pre_catequistas" element={<ListaPrecatequistas />} />} />
          <Route path="/precatequistas_estado" element={<ProtectedRoute role="formador_pre_catequistas" element={<PrecatequistasEstado />} />} />
          <Route path="/asistencia_qr_precatequistas" element={<ProtectedRoute role="formador_pre_catequistas" element={<AsistenciaEntradaQrPage />} />} />
          <Route path="/asistencia_salida_qr_catequistas2" element={<ProtectedRoute role="formador_pre_catequistas" element={<AsistenciaSalidaQrPage />} />} />

          <Route path="/formador" element={<ProtectedRoute role="formador_iniciados" element={<Formador />} />} />
          <Route path="/lista_iniciados" element={<ProtectedRoute role="formador_iniciados" element={<ListaIniciados />} />} />
          <Route path="/iniciados_estado" element={<ProtectedRoute role="formador_iniciados" element={<IniciadosEstado />} />} />
          <Route path="/asistencia_qr_iniciados" element={<ProtectedRoute role="formador_iniciados" element={<AsistenciaEntradaQrPage />} />} />
          <Route path="/asistencia_salida_qr_catequistas3" element={<ProtectedRoute role="formador_iniciados" element={<AsistenciaSalidaQrPage />} />} />



          

          <Route path='/prueba' element={<Prueba></Prueba>} ></Route>
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;
