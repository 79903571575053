import React, { useState, useEffect } from 'react';
import { useData } from '../../DataContext';
import { loadImage } from '../../../loadImage';

export default function ComunidadConfirmando() {
    const { usuario, loading } = useData();
    const [datos, setDatos] = useState(null);
    const [imagen, setImagen] = useState(null);

    useEffect(() => {
        const fetchDatos = async () => {
            if (usuario && usuario.codigo) {
                try {
                    const response = await fetch(`https://backend.korindex.com/lista_general/${usuario.codigo}`);
                    const data = await response.json();
                    setDatos(data);
                    console.log('Comunidad:', data.COMUNIDAD);

                    // Cargar la imagen usando loadImage
                    const imagenCargada = loadImage(data.COMUNIDAD);
                    setImagen(imagenCargada);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchDatos();
    }, [usuario]);

    if (loading || !datos || !imagen) {
        return <div>Cargando...</div>;
    }

    return (
        <div className='pt-24 w-screen'>
            <div className='flex md:flex-row flex-col w-full md:space-x-6 px-6 max-md:space-y-7'>
                <div className='flex flex-col md:w-1/2 max-md:py-4 bg-rose-800 px-4 rounded-2xl justify-center items-center space-y-3'>
                    <div className='h-2/3 flex w-full justify-center'>
                        <img
                            className='h-full'
                            src={imagen}
                            alt={`Imagen Comunidad ${datos.COMUNIDAD}`}
                        />
                    </div>

                    <div className='bg-black texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-full'>
                        Comunidad: {datos.COMUNIDAD}
                    </div>
                </div>
                <div className='flex flex-col md:w-1/2 max-md:py-4 bg-white px-4 rounded-2xl justify-center items-center space-y-3 border-4 border-red-800'>
                    <div className='bg-black texto_estirado text-white text-3xl font-bold rounded-2xl p-3 text-center w-full'>
                        Comunidad: {datos.COMUNIDAD}
                    </div>
                </div>

            </div>
        </div>
    );
}
