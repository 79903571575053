import React from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

export default function RegistrarUsuarios() {
  // Lista de usuarios con sus correos y contraseñas
  const usuarios = [
    { correo: "cindy_74_ecsa@gmail.com", contraseña: "Y87B74" },
    { correo: "cristian_72_ecsa@gmail.com", contraseña: "69021D" },
    { correo: "david_71_ecsa@gmail.com", contraseña: "O810W6" },
    { correo: "daysi_70_ecsa@gmail.com", contraseña: "43125K" },
    { correo: "eber_71_ecsa@gmail.com", contraseña: "7O610H" },
    { correo: "erik_70_ecsa@gmail.com", contraseña: "664Y4D" },
    { correo: "jhonathan_70_ecsa@gmail.com", contraseña: "83B34J" },
    { correo: "jorge_70_ecsa@gmail.com", contraseña: "51P5E5" },
    { correo: "juan_41_ecsa@gmail.com", contraseña: "F931T8" },
    { correo: "karen_70_ecsa@gmail.com", contraseña: "T4I641" },
    { correo: "leslie_72_ecsa@gmail.com", contraseña: "J74461" },
    { correo: "marco_74_ecsa@gmail.com", contraseña: "465K7Z" },
    { correo: "mayda_74_ecsa@gmail.com", contraseña: "V28K79" },
    { correo: "paúl_70_ecsa@gmail.com", contraseña: "27B521" },
    { correo: "sheyla_75_ecsa@gmail.com", contraseña: "L64G48" },
    { correo: "terry_70_ecsa@gmail.com", contraseña: "35M722" },
    { correo: "alan_60_ecsa@gmail.com", contraseña: "P5H012" },
    { correo: "ana_76_ecsa@gmail.com", contraseña: "99S19W" },
    { correo: "anali_71_ecsa@gmail.com", contraseña: "686L6R" },
    { correo: "andrea_78_ecsa@gmail.com", contraseña: "2UN791" },
    { correo: "angie_60_ecsa@gmail.com", contraseña: "30135U" },
    { correo: "carlos_76_ecsa@gmail.com", contraseña: "2B2591" },
    { correo: "clara_75_ecsa@gmail.com", contraseña: "N631U1" },
    { correo: "cristhian_60_ecsa@gmail.com", contraseña: "8CT822" },
    { correo: "dany_60_ecsa@gmail.com", contraseña: "L7C744" },
    { correo: "gimena_61_ecsa@gmail.com", contraseña: "1064SP" },
    { correo: "gustavo_73_ecsa@gmail.com", contraseña: "2QP313" },
    { correo: "jhoselin_74_ecsa@gmail.com", contraseña: "8D891Z" },
    { correo: "jose_60_ecsa@gmail.com", contraseña: "33T6S1" },
    { correo: "joseph_61_ecsa@gmail.com", contraseña: "530W07" },
    { correo: "leydi_71_ecsa@gmail.com", contraseña: "M4999D" },
    { correo: "magdalena_73_ecsa@gmail.com", contraseña: "I881O2" },
    { correo: "rocio_74_ecsa@gmail.com", contraseña: "9O6203" },
    { correo: "sebastian_61_ecsa@gmail.com", contraseña: "Z8D904" },
    { correo: "sulenca_72_ecsa@gmail.com", contraseña: "N6P978" },
    { correo: "yhoseph_61_ecsa@gmail.com", contraseña: "4302RI" },
    { correo: "zeider_71_ecsa@gmail.com", contraseña: "74GS00" },
    { correo: "ana_78_ecsa@gmail.com", contraseña: "D1122G" },
    { correo: "angela_71_ecsa@gmail.com", contraseña: "379D0L" },
    { correo: "cristian_77_ecsa@gmail.com", contraseña: "U396U7" },
    { correo: "eylem_62_ecsa@gmail.com", contraseña: "U2Z539" },
    { correo: "fabrizio_71_ecsa@gmail.com", contraseña: "47T30I" },
    { correo: "harry_72_ecsa@gmail.com", contraseña: "89K38G" },
    { correo: "karol_75_ecsa@gmail.com", contraseña: "399X0D" },
    { correo: "maria_74_ecsa@gmail.com", contraseña: "H785I4" },
    { correo: "mayled_60_ecsa@gmail.com", contraseña: "WY5518" },
    { correo: "midian_76_ecsa@gmail.com", contraseña: "1J5M50" },
    { correo: "pablo_75_ecsa@gmail.com", contraseña: "60092B" },
    { correo: "sahun_71_ecsa@gmail.com", contraseña: "Z208E9" },
    { correo: "zulema_75_ecsa@gmail.com", contraseña: "Q10P26" }
  ];

  // Función para registrar usuarios en Firebase Authentication
  const registrarUsuarios = async () => {
    const auth = getAuth();

    for (const usuario of usuarios) {
      try {
        await createUserWithEmailAndPassword(auth, usuario.correo, usuario.contraseña);
        console.log(`Usuario registrado: ${usuario.correo}`);
      } catch (error) {
        console.error(`Error al registrar ${usuario.correo}:`, error.message);
      }
    }
    alert('Todos los usuarios han sido registrados.');
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-6 text-center">Registrar Usuarios</h1>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-800 text-white">
            <th className="py-3 px-6 text-left">Correo</th>
            <th className="py-3 px-6 text-left">Contraseña</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario, index) => (
            <tr key={index} className="border-b border-gray-300 hover:bg-gray-100">
              <td className="py-3 px-6">{usuario.correo}</td>
              <td className="py-3 px-6">{usuario.contraseña}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center mt-6">
        <button
          onClick={registrarUsuarios}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Registrar todos los usuarios
        </button>
      </div>
    </div>
  );
}
