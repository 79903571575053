import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, collection, getDocs } from "firebase/firestore";

// Crear el contexto
const DataContext = createContext();

// Crear un proveedor para el contexto
export const DataProvider = ({ children }) => {
  const [listaGeneral, setListaGeneral] = useState([]);
  const [asistenciaGeneral, setAsistenciaGeneral] = useState([]);
  const [listaCatequistas, setListaCatequistas] = useState([]); // Nuevo estado para la lista de catequistas
  const [usuario, setUsuario] = useState(null); // Estado para la información del usuario
  const [rolesDisponibles, setRolesDisponibles] = useState([]); // Estado para los roles
  const [loading, setLoading] = useState(true);

  // Función para obtener y actualizar los datos
  const fetchData = async () => {
    console.log('Iniciando la actualización de datos...'); // Mensaje de estado de carga
    const backendUrl = process.env.REACT_APP_BACKEND_URL; // Usa la variable de entorno

    try {
      const [listaGeneralResponse, asistenciaGeneralResponse, listaCatequistasResponse] = await Promise.all([
        axios.get(`${backendUrl}/confirmandos`),
        axios.get(`${backendUrl}/confirmandos_asistencia`),
        axios.get(`${backendUrl}/catequistas`) // Nueva petición para obtener la lista de catequistas
      ]);

      // Actualiza los estados con los datos nuevos
      setListaGeneral(listaGeneralResponse.data);
      setAsistenciaGeneral(asistenciaGeneralResponse.data);
      setListaCatequistas(listaCatequistasResponse.data); // Actualiza el estado con la lista de catequistas

      // Guarda los datos nuevos en localStorage
      localStorage.setItem('listaGeneral', JSON.stringify(listaGeneralResponse.data));
      localStorage.setItem('asistenciaGeneral', JSON.stringify(asistenciaGeneralResponse.data));
      localStorage.setItem('listaCatequistas', JSON.stringify(listaCatequistasResponse.data)); // Guarda la lista de catequistas en localStorage

      console.log('Datos actualizados y guardados en localStorage.');

    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }

    // Obtiene la información del usuario autenticado
    const fetchUser = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const db = getFirestore();
          const userDocRef = doc(db, "usuarios", user.email);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUsuario(userData);

            // Guarda la información del usuario en localStorage
            localStorage.setItem('usuario', JSON.stringify(userData));
            console.log('Información del usuario obtenida de Firestore y guardada en localStorage.');
          } else {
            console.log("No se encontró un usuario con este correo en Firestore.");
          }
        }
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
      } finally {
        setLoading(false);
        console.log('Datos de usuario y de la aplicación cargados.');
      }
    };

    fetchUser();
  };

  // Función para recargar los datos
  const reloadData = async () => {
    console.log('Iniciando la recarga de datos...');
    setLoading(true); // Establece el estado de carga en verdadero
    try {
      await fetchData(); // Llama a fetchData para actualizar los datos

      // Imprime los datos actualizados en la consola
      // console.log('Datos actualizados:');
      // console.log('Lista General:', listaGeneral);
      // console.log('Asistencia General:', asistenciaGeneral);
      // console.log('Usuario:', usuario);

      console.log('Datos recargados con éxito.');
    } catch (error) {
      console.error('Error al recargar los datos:', error);
    } finally {
      setLoading(false); // Establece el estado de carga en falso
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cargarRolesDisponibles = async (email) => {
    try {
      console.log('Cargando roles disponibles...');
      const roles = [];
      const db = getFirestore();
      const categoriasSnapshot = await getDocs(collection(db, 'categoria'));

      categoriasSnapshot.forEach((doc) => {
        const data = doc.data();
        for (const key in data) {
          if (data[key] === email) {
            roles.push(doc.id); // Agrega el nombre del documento (categoría) al array de roles disponibles
          }
        }
      });

      setRolesDisponibles(roles); // Actualiza el estado de rolesDisponibles
      console.log('Roles disponibles cargados con éxito:', roles);
    } catch (error) {
      console.error('Error al cargar las categorías:', error);
    }
  };

  return (
    <DataContext.Provider value={{ listaGeneral, asistenciaGeneral, listaCatequistas, usuario, rolesDisponibles, cargarRolesDisponibles, reloadData, loading }}>
      {children}
    </DataContext.Provider>
  );
};

// Crear un hook personalizado para usar el contexto
export const useData = () => {
  return useContext(DataContext);
};
