import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import { auth, db } from '../../firebase'; // Importa tu configuración de Firebase
import ecsa_logo from '../../assets/images/ecsa_logo.png';

export default function InicioSesionCorreo() {
    const [error, setError] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputContraseña, setInputContraseña] = useState('');
    const navigate = useNavigate();

    // Maneja el cambio de input del correo y la contraseña
    const handleEmailChange = (e) => setInputEmail(e.target.value);
    const handleContraseñaChange = (e) => setInputContraseña(e.target.value);

    // Función para verificar correo y contraseña en Firebase Authentication
    const verificarCorreoYContraseña = async () => {
        if (!inputEmail || !inputContraseña) {
            setError('Por favor ingresa tu correo y contraseña.');
            return;
        }
    
        try {
            const userCredential = await signInWithEmailAndPassword(auth, inputEmail, inputContraseña);
            if (userCredential.user) {
                cargarPrimeraCategoria(inputEmail); // Carga la primera categoría automáticamente
            } else {
                setError('Correo o contraseña incorrecta.');
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            if (error.code === 'auth/wrong-password') {
                setError('Contraseña incorrecta.');
            } else if (error.code === 'auth/user-not-found') {
                setError('Usuario no encontrado.');
            } else if (error.code === 'auth/invalid-email') {
                setError('Correo inválido.');
            } else {
                setError('Error al iniciar sesión. Por favor intenta de nuevo.');
            }
        }
    };
    

    // Función que carga la primera categoría disponible y redirige automáticamente
    const cargarPrimeraCategoria = async (email) => {
        try {
            const categoriasSnapshot = await getDocs(collection(db, 'categoria'));
            let primeraCategoria = '';

            categoriasSnapshot.forEach((doc) => {
                const data = doc.data();
                for (const key in data) {
                    if (data[key] === email && !primeraCategoria) {
                        primeraCategoria = doc.id; // Toma la primera categoría en la que el correo está registrado
                        break;
                    }
                }
            });

            if (primeraCategoria) {
                navigate(`/${primeraCategoria}`); // Redirige a la primera categoría encontrada
            } else {
                setError('No se encontraron categorías asociadas a este usuario.');
            }
        } catch (error) {
            setError('Error al cargar las categorías.');
        }
    };

    return (
        <div className='flex w-full h-screen text-black justify-center items-center bg-slate-100'>
            <div className='max-sm:w-full max-sm:mx-4 flex flex-col space-y-5 bg-white w-1/2 px-8 py-12 sm:px-24 sm:py-24 justify-center items-center rounded-xl'>
                <img className='h-24 mb-4' src={ecsa_logo} alt="Logo" />
                <div className='text-2xl font-semibold'>Iniciar Sesión</div>
                {error && <div className="text-red-500 text-center mt-2">{error}</div>}
                <div className='w-full'>
                    <input
                        type='email'
                        value={inputEmail}
                        onChange={handleEmailChange}
                        placeholder='Correo electrónico'
                        className='bg-slate-100 rounded-lg p-2 w-full mb-4'
                    />
                </div>
                <div className='w-full'>
                    <input
                        type='password'
                        value={inputContraseña}
                        onChange={handleContraseñaChange}
                        placeholder='Contraseña'
                        className='bg-slate-100 rounded-lg p-2 w-full mb-4'
                    />
                </div>
                <button
                    onClick={verificarCorreoYContraseña}
                    className="mt-4 bg-blue-500 text-white p-2 rounded-lg w-full"
                >
                    Verificar
                </button>
            </div>
        </div>
    );
}
